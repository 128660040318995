import React, { memo, useEffect } from "react";

import { useMutation } from "@apollo/react-hooks";
import useSelector from "../../hooks/useSelector";
import useActions from "../../hooks/useActions";
import { userActions } from "../../redux/reducers/user";

import AppLoadingView from "../views/AppLoadingView";
import AppCrashView from "../views/AppCrashView";
import { RegisterMutation, registerMutation } from 'bridge';
/**
 * Вью регистрации пользователя
 * @type {React.NamedExoticComponent<object>}
 */
const Registration = memo(() => {
  const { setUserRegistered } = useActions({
    setUserRegistered: userActions.setUserRegistered,
  });
  const user = useSelector((state) => state.user);
  const [register, { loading, error, data }] = useMutation<
    RegisterMutation,
    RegisterMutation.Arguments
  >(registerMutation, {
    variables: {
      input: {
        userId: 0,
      },
    },
  });

  useEffect(() => {
    // Когда информация о пользователе и карте известны, устанавливаем флаг
    // что форма заполнена
    if (user) {
      register();
    }
  }, [user, register]);

  useEffect(() => {
    // Как только пользователь зарегистрирован, ставим флаг в Redux
    if (data) {
      setUserRegistered();
    }
  }, [data, setUserRegistered]);

  // Пока запрос отправляется, отображаем лоадер
  if (loading) {
    return <AppLoadingView />;
  }

  // При возникновении ошибки отображаем вью краша
  if (error) {
    return <AppCrashView onRestartClick={register} error={error.message} />;
  }

  return <AppLoadingView />;
});

export default Registration;
