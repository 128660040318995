/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import TimerSettings from "../TimerSettings";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import {
  View,
  Panel,
  PanelHeader,
  Tabs,
  TabsItem,
  FixedLayout,
} from "@vkontakte/vkui";
import { Theme } from "../../theme/types";
import useActions from "../../hooks/useActions";
import { timerActions } from "../../redux/reducers/timer";
import useSelector from "../../hooks/useSelector";
import useAlertContext from "../AlertContext/useAlertContext";
import {
  SESSION_STATUS,
  SwitchSettingsPresetMutation,
  switchSettingsPresetMutation,
  Todo,
  IsMessagesFromGroupAllowedQuery,
  isMessagesFromGroupAllowedQuery,
} from "bridge";
import ConfirmResetAlert from "../alerts/ConfirmResetAlert";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { userActions } from "../../redux/reducers/user";
import { ReduxState } from "../../redux/types";

const styles = makeStyles((theme: Theme) => ({
  root: {
    background: "white",
    paddingBottom: 60,
    paddingTop: 50,
    padding: "12px 0px",
  },
  header: {
    height: "52px",
    display: "flex",
    fontSize: 27,
    alignItems: "center",
    fontFamily: '"TT Commons", -apple-system, Roboto, Helvetica Neue, Arial',
    fontWeight: 700,
    marginLeft: 17,
    justifycontent: "center",
    color: "#4FC16F",
  },
  tabs: {
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightMedium,
    padding: "0px 6px",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.06)",
    "& .Tabs__in .TabsItem--selected::after": {
      background: theme.palette.green,
      height: 3,
      borderRadius: 30,
    },
    "& .TabsItem": {
      fontSize: 16,
      color: "#515151",
      opacity: 0.6,
      fontWight: 500,
    },
    "& .TabsItem--selected": {
      color: "#515150",
      opacity: 1,
    },
  },
  separator: {
    "& .Separator__in": {
      boxShadow: "0px 2px 4px 3px rgba(0, 0, 0, 0.06)",
    },
  },
}));

const SettingsView = () => {
  const { openAlert } = useAlertContext();

  const { reset } = useActions({
    reset: timerActions.reset,
  });

  const { setCurrentSettings } = useActions({
    setCurrentSettings: timerActions.setCurrentSettings,
  });

  const classes = styles();
  const currentSettings = useSelector(
    (state: ReduxState) => state.timer.currentSettings
  );
  const completedCycles = useSelector((state) => state.timer.completedCycles);

  const { setApplesCount } = useActions({
    setApplesCount: userActions.setApplesCount,
  });

  const notificationSettings = useSelector(
    (state: ReduxState) => state.user.notificationSettings
  );

  const todos = useSelector((state) => state.todos);
  const activeTodo = todos.find((todo: Todo) => todo.active === true);

  const [
    isMessagesFromGroupAllowedRemote,
    { loading, data: remoteMessagesFromGroupData },
  ] = useLazyQuery<IsMessagesFromGroupAllowedQuery>(
    isMessagesFromGroupAllowedQuery,
    { fetchPolicy: "network-only" }
  );

  const currentCycle = useSelector(
    (state: ReduxState) => state.timer.currentCycle
  );

  const settings = useSelector(
    (state: ReduxState) => state.timer.settings[currentSettings]
  );

  const [switchSettingsRemote] = useMutation<
    SwitchSettingsPresetMutation,
    SwitchSettingsPresetMutation.Arguments
  >(switchSettingsPresetMutation);

  const { setNotificationsSettings } = useActions({
    setNotificationsSettings: userActions.setNotificationsSettings,
  });

  const sessionStatus = useSelector(
    (state: ReduxState) => state.timer.sessionStatus
  );
  const [activeTab, setActiveTab] = useState(currentSettings);
  const [notifications, setNotifications] = useState<boolean | "loading">(
    "loading"
  );

  const resetStatus =
    sessionStatus === SESSION_STATUS.START &&
    currentCycle === settings.longBreakCycles
      ? true
      : false;

  const handleTabClicks = (tabType: "classic" | "custom") => {
    if (resetStatus) {
      setActiveTab(tabType);
      setCurrentSettings(tabType);
      switchSettingsRemote({
        variables: {
          currentSettings: tabType,
        },
      });
      return;
    }
    openAlert(
      <ConfirmResetAlert
        onConfirm={() => {
          reset();
          if (completedCycles > 0 && !activeTodo) {
            setApplesCount(completedCycles);
          }
          setActiveTab(tabType);
          setCurrentSettings(tabType);
          switchSettingsRemote({
            variables: {
              currentSettings: tabType,
            },
          });
        }}
        mainContent={"Чтобы поменять профиль настроек, нужно сбросить таймер"}
        header={"Сбросить таймер?"}
      />
    );
  };

  useEffect(() => {
    isMessagesFromGroupAllowedRemote();
  }, []);

  useEffect(() => {
    if (
      remoteMessagesFromGroupData &&
      remoteMessagesFromGroupData?.isMessagesFromGroupAllowed !== undefined
    ) {
      if (
        !notificationSettings.isWorkingWithServerNotification &&
        remoteMessagesFromGroupData?.isMessagesFromGroupAllowed
      ) {
        setNotificationsSettings({
          isAllowedMessagesFromGroup:
            remoteMessagesFromGroupData?.isMessagesFromGroupAllowed,
          isAskedForAllowMessages: true,
          isWorkingWithServerNotification: remoteMessagesFromGroupData?.isMessagesFromGroupAllowed
            ? true
            : false,
        });
      } else if (!remoteMessagesFromGroupData?.isMessagesFromGroupAllowed) {
        setNotificationsSettings({
          isAllowedMessagesFromGroup: false,
          isAskedForAllowMessages: true,
          isWorkingWithServerNotification: false,
        });
      }

      setNotifications(remoteMessagesFromGroupData?.isMessagesFromGroupAllowed);
    }
  }, [loading]);

  return (
    <View activePanel={"settings"}>
      <Panel id={"settings"} separator={false}>
        <PanelHeader
          left={<div className={classes.header}>Настройки</div>}
        ></PanelHeader>
        <FixedLayout style={{ width: "100%" }}>
          <Tabs className={classes.tabs} mode="default">
            <TabsItem
              selected={activeTab === "classic"}
              onClick={(e) => {
                e.stopPropagation();
                handleTabClicks("classic");
              }}
            >
              Классические
            </TabsItem>
            <TabsItem
              selected={activeTab === "custom"}
              onClick={(e) => {
                e.stopPropagation();
                handleTabClicks("custom");
              }}
            >
              Альтернативные
            </TabsItem>
          </Tabs>
        </FixedLayout>

        {currentSettings === "classic" && (
          <TimerSettings styles={classes.root} notifications={notifications} />
        )}
        {currentSettings === "custom" && (
          <TimerSettings
            styles={classes.root}
            type={"custom"}
            notifications={notifications}
          />
        )}
      </Panel>
    </View>
  );
};

export default SettingsView;
