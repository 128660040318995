/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import useInterval from "../../hooks/useInterval";
import { useSelector } from "react-redux";
import { ReduxState } from "../../redux/types";
import useActions from "../../hooks/useActions";
import { timerActions } from "../../redux/reducers/timer";
import { chooseSessionType } from "./utils";
import TimerProgress from "../TimerProgress";
import { SESSION_STATUS, SESSION_TYPE } from "bridge";
import { Todo, todoActions } from "../../redux/reducers/todo";

const Timer = () => {
  const { countdown } = useActions({
    countdown: timerActions.countdown,
  });
  const { setTimerTicking } = useActions({
    setTimerTicking: timerActions.setTimerTicking,
  });
  const { setSessionType } = useActions({
    setSessionType: timerActions.setSessionType,
  });

  const { setSessionStatus } = useActions({
    setSessionStatus: timerActions.setSessionStatus,
  });

  const { updateCurrentTime } = useActions({
    updateCurrentTime: timerActions.updateCurrentTime,
  });

  const { setCompletedCycles } = useActions({
    setCompletedCycles: timerActions.setCompletedCycles,
  });

  const { updateLongBreakCyclesLeft } = useActions({
    updateLongBreakCyclesLeft: timerActions.updateLongBreakCyclesLeft,
  });

  const { editTodo } = useActions({
    editTodo: todoActions.editTodo,
  });

  const currentTimer = useSelector((state: ReduxState) => state.timer);
  const {
    sessionType,
    settings: timerSettings,
    currentCycle,
    completedCycles,
    currentSettings,
  } = currentTimer;

  const timerStatus = useSelector((state: ReduxState) => state.timer.ticking);
  const currentTime = useSelector(
    (state: ReduxState) => state.timer.currentTime
  );

  const todos = useSelector((state: ReduxState) => state.todos);
  const activeTodo = todos.find((todo: Todo) => todo.active === true);

  useEffect(() => {
    if (currentTime < 0) {
      if (
        activeTodo &&
        sessionType === SESSION_TYPE.POMODORO &&
        activeTodo.amountCycles !== activeTodo.completedCycles
      ) {
        editTodo({
          _id: activeTodo._id,
          completedCycles: activeTodo.completedCycles + 1,
        });
      }
      if (sessionType === SESSION_TYPE.POMODORO && !activeTodo) {
        setCompletedCycles(false);
        updateLongBreakCyclesLeft({ reset: false });
      }

      const newSessionType = chooseSessionType(sessionType, currentCycle);
      if (
        newSessionType === SESSION_TYPE.POMODORO &&
        activeTodo &&
        activeTodo.completedCycles === activeTodo.amountCycles &&
        activeTodo.amountCycles < 10
      ) {
        editTodo({
          _id: activeTodo._id,
          amountCycles: activeTodo.amountCycles + 1,
        });
      }

      setTimerTicking(
        newSessionType === SESSION_TYPE.POMODORO
          ? timerSettings[currentSettings].timerAutoStart
          : timerSettings[currentSettings].breaksAutoStart
      );

      if (
        (!timerSettings[currentSettings].timerAutoStart &&
          newSessionType === SESSION_TYPE.POMODORO) ||
        (!timerSettings[currentSettings].breaksAutoStart &&
          newSessionType !== SESSION_TYPE.POMODORO)
      ) {
        setSessionStatus(SESSION_STATUS.START);
      }

      const newCurrentTime = (newSessionType: SESSION_TYPE) => {
        if (newSessionType === SESSION_TYPE.LONGBREAK)
          return timerSettings[currentSettings].longBreakTime;
        if (newSessionType === SESSION_TYPE.BREAK)
          return timerSettings[currentSettings].breakTime;
        else return timerSettings[currentSettings].timerTime;
      };
      setSessionType(newSessionType);

      updateCurrentTime(newCurrentTime(newSessionType));
    }
  }, [currentTime, sessionType]);

  useInterval(
    () => {
      countdown();
    },
    timerStatus ? 1000 : null
  );

  return (
    <TimerProgress
      time={currentTime}
      sessionType={sessionType}
      completedCycles={
        activeTodo ? activeTodo.completedCycles : completedCycles
      }
    />
  );
};

export default React.memo(Timer);
