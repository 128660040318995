/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import Todo from "../Todo";
import { Theme } from "../../theme/types";
import { Todos, Todo as TTodo, todoActions } from "../../redux/reducers/todo";
import useSelector from "../../hooks/useSelector";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useActions from "../../hooks/useActions";
import { useMutation } from "@apollo/react-hooks";
import { ChangeTodoIndexMutation, changeTodoIndexMutation } from "bridge";
import { TransitionGroup } from "react-transition-group";
import { ReduxState } from "../../redux/types";

export interface ITodos {
  title: string;
  todosQty: number;
  todosTimeSum: number;
  todos: Todos;
  completed: boolean;
}
const styles = makeStyles((theme: Theme) => ({
  root: {},
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#C1C1C1",
  },
  container: {
    margin: "0 21px",
    paddingTop: 18,
  },
  separator: {
    height: 1,
    borderTop: "1.5px solid #F3F3F3",
    borderRadius: 30,
    margin: "0px 21px",
    marginTop: 19,
  },
  title: {
    padding: 0,
    margin: 0,
    color: "#515151",
    fontSize: 22,
    fontFamily: theme.typography.fontFamilyTT,
    marginBottom: 7,
  },
  subTitle: {
    color: "#B4B4B4",
    fontSize: "16px",
    marginBottom: "5px",
    fontFamily: theme.typography.fontFamilyTT,
    "& span": {
      marginLeft: 5,
      color: "#515151",
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  todosContainer: {
    marginTop: 19,
    width: "100%",
  },
}));

const TodosContainer = ({ title, todos, completed }: ITodos) => {
  const classes = styles();

  const [changeTodoIndexRemote] = useMutation<
    ChangeTodoIndexMutation,
    ChangeTodoIndexMutation.Arguments
  >(changeTodoIndexMutation);

  const { editTodo } = useActions({
    editTodo: todoActions.editTodo,
  });

  const { updateIndexes } = useActions({
    updateIndexes: todoActions.updateIndexes,
  });

  const todosSortedByIndex = todos.sort((a, b) => a.index - b.index);

  const plannedTodos = todosSortedByIndex
    .filter((todo) => todo.completed === false)
    .sort((a, b) => +b.active - +a.active);

  const size = 10;
  const completedTodos = todosSortedByIndex
    .filter((todo) => todo.completed === true)
    .sort((a, b) => b.index - a.index)
    .slice(0, size);

  const activeTodo = todos.find((todo) => todo.active === true) as TTodo;

  const [finalTodos, setFinalTodos] = useState<TTodo[]>(
    completed ? completedTodos : plannedTodos
  );

  useEffect(() => {
    if (completed) {
      setFinalTodos(completedTodos);
    } else {
      setFinalTodos(plannedTodos);
    }
  }, [todos, activeTodo]);

  const currentSettings = useSelector(
    (state: ReduxState) => state.timer.currentSettings
  );

  const allSettings = useSelector((state: ReduxState) => state.timer.settings);

  const getAllAmountCycles = Math.round(
    plannedTodos.reduce((accum, cur) => {
      return accum + (cur.amountCycles - cur.completedCycles);
    }, 0)
  );
  const plannedTodosSum =
    getAllAmountCycles * (allSettings[currentSettings].timerTime / 60);

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const items = reorder(
      finalTodos,
      result.source.index,
      result.destination.index
    ) as TTodo[];

    const source = finalTodos[result.source.index];
    const destination = finalTodos[result.destination.index];

    const type =
      Math.abs(result.source.index) > Math.abs(result.destination.index)
        ? "plus"
        : "minus";

    if (Math.abs(result.source.index - result.destination.index) <= 1) {
      editTodo({ _id: source._id, index: destination.index });
      editTodo({ _id: destination._id, index: source.index });
    } else {
      editTodo({
        _id: source._id,
        index: destination.index,
      });
      updateIndexes({
        _id: source._id,
        sourceIndex: source.index,
        destIndex: destination.index,
        type: type,
      });
    }

    changeTodoIndexRemote({
      variables: {
        input: {
          _id: source._id,
          index: destination.index,
        },
      },
    });

    setFinalTodos(items);
  };

  return (
    <>
      {title === "Выполненные" ? (
        <div className={classes.separator}></div>
      ) : null}
      <div className={classes.container}>
        <h1 className={classes.title}>{title}</h1>
        {!completed && (
          <div className={classes.subTitle}>
            Количество задач:
            <span>
              {completed ? completedTodos.length : plannedTodos.length}
            </span>
          </div>
        )}
        {!completed && (
          <div className={classes.subTitle}>
            Общая продолжительность:
            <span>{`${plannedTodosSum} минут`} </span>
          </div>
        )}

        <div className={classes.todosContainer}>
          {!completed ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <TransitionGroup component={null}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {finalTodos.map((todo, i) => {
                        if (!todo.active) {
                          return (
                            <Draggable
                              key={todo._id}
                              draggableId={todo._id}
                              index={i}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Todo
                                    _id={todo._id}
                                    /*    key={todo._id} */
                                    label={todo.title}
                                    timerQty={todo.amountCycles}
                                    doneQty={todo.completedCycles}
                                    isActive={todo.active}
                                    completed={todo.completed}
                                    fromMain={false}
                                    activeTodo={activeTodo ? activeTodo : false}
                                    index={todo.index}
                                  ></Todo>
                                </div>
                              )}
                            </Draggable>
                          );
                        } else {
                          return (
                            <Todo
                              _id={todo._id}
                              key={todo._id}
                              label={todo.title}
                              timerQty={todo.amountCycles}
                              doneQty={todo.completedCycles}
                              isActive={todo.active}
                              completed={todo.completed}
                              fromMain={false}
                              activeTodo={activeTodo ? activeTodo : false}
                              index={todo.index}
                            ></Todo>
                          );
                        }
                      })}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </TransitionGroup>
            </DragDropContext>
          ) : (
            finalTodos.map((todo) => {
              return (
                <Todo
                  _id={todo._id}
                  key={todo._id}
                  label={todo.title}
                  timerQty={todo.amountCycles}
                  doneQty={todo.completedCycles}
                  isActive={todo.active}
                  completed={todo.completed}
                  fromMain={false}
                  activeTodo={activeTodo ? activeTodo : false}
                  index={todo.index}
                ></Todo>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(TodosContainer);
