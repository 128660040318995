//@ts-ignore
import image1 from "../../../assets/onboarding-1.base64.png";
import image2 from "../../../assets/onboarding-2.base64.png";
import image3 from "../../../assets/onboarding-3.base64.png";
import image4 from "../../../assets/onboarding-4.base64.png";

interface Slide {
  title: string;
  image: string;
  description: string;
  buttonText: string;
}

export const onboardingSlides: Slide[] = [
  {
    title: "Привет!",
    image: image1,
    description:
      "Done Timer повысит эффективность " +
      "вашей работы и поможет не отвлекаться " +
      "от важных дел",
    buttonText: "Далее",
  },
  {
    title: "Как это работает?",
    image: image2,
    description:
      "Планируйте задачи и выполняйте их " +
      "короткими отрезками с перерывами на отдых",
    buttonText: "Далее",
  },
  {
    title: "Прогресс",
    image: image3,
    description:
      "Пока вы работаете, на дереве растут яблоки. " +
      "Больше яблок — больше выполненных задач.",
    buttonText: "Далее",
  },

  {
    title: "Включите уведомления",
    image: image4,
    description:
      "Вам не нужно следить за таймером — мы напомним, когда придёт время отдохнуть",
    buttonText: "Начать!",
  },
];
