import { ofType, unionize, UnionOf } from "unionize";
import { unionizeConfig } from "../utils";
import {
  Insets,
  DefaultUpdateConfigData,
  UpdateConfigData,
  MVKUpdateConfigData,
} from "@vkontakte/vk-bridge";
import { Config } from "../../config";
import { LaunchParams } from "../../types/launch-params";

type ConfigData = Omit<DefaultUpdateConfigData, "app_id"> & MVKUpdateConfigData;

export interface ConfigReducerState extends ConfigData {
  appId: string;
  appConfig: Config;
  viewportWidth: number;
  viewportHeight: number;
  launchParams: LaunchParams;
}

export const configActions = unionize(
  {
    updateConfig: ofType<UpdateConfigData>(),
    updateInsets: ofType<Insets>(),
  },
  unionizeConfig
);

type ConfigAction = UnionOf<typeof configActions>;

const initialState: ConfigReducerState = {
  app: "vkclient",
  appConfig: {
    apiBaseUrl: "",
    group_id: 0,
  },
  appId: "",
  appearance: "light",
  scheme: "client_light",
  insets: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  viewportHeight: 0,
  viewportWidth: 0,
  launchParams: {
    accessTokenSettings: [],
    appId: 0,
    areNotificationsEnabled: false,
    isAppUser: false,
    isFavorite: false,
    language: "ru",
    platform: "desktop_web",
    ref: "other",
    userId: 0,
    groupId: null,
    viewerGroupRole: null,
    sign: "",
  },
  api_host: '',
};

/**
 * Редьюсер ответственный за конфиг приложения который приходит от ВКонтакте.
 */
function configReducer(
  state: ConfigReducerState = initialState,
  action: ConfigAction
) {
  return configActions.match(action, {
    updateConfig: (config) => {
      if ("insets" in config) {
        const { app_id, ...restConfig } = config;

        return {
          ...state,
          ...restConfig,
          appId: app_id,
        };
      }
      const { ...restConfig } = config;

      return {
        ...state,
        ...restConfig,
      };
    },
    updateInsets: (insets) => ({ ...state, insets }),
    default: () => state,
  });
}

export default configReducer;
