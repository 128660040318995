import React from "react";

import { makeStyles } from "@material-ui/styles";

import useInsets from "../../hooks/useInsets";
import { InsetsInterface } from "../../types/custom-types";
import { Theme } from "../../theme/types";

export const APP_HEADER_HEIGHT = 52;

const useStyles = makeStyles<Theme, InsetsInterface>((theme) => ({
  root: {
    background: "white",
    height: APP_HEADER_HEIGHT,
    position: "fixed",
    paddingTop: (props) => props.top,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    overflow: "hidden",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.06)",
    display: "flex",
    alignItems: "center",
  },
  title: {
    color: theme.palette.green,
    fontSize: 27,
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.fontFamilyTT,
    padding: 0,
    margin: 0,
    paddingLeft: 21,
  },
}));

const AppHeader = ({ title }: { title: string }) => {
  const insets = useInsets();
  const mc = useStyles(insets);

  return (
    <div className={mc.root}>
      <h1 className={mc.title}>{title}</h1>
    </div>
  );
};

export default React.memo(AppHeader);
