// Доступные временные отрезки
export const settingsOptions = {
  pomodoro: [
    { value: 300, label: "5 минут" },
    { value: 600, label: "10 минут" },
    { value: 900, label: "15 минут" },
    { value: 1200, label: "20 минут" },
    { value: 1500, label: "25 минут" },
    { value: 1800, label: "30 минут" },
    { value: 2100, label: "35 минут" },
    { value: 2400, label: "40 минут" },
    { value: 2700, label: "45 минут" },
    { value: 3000, label: "50 минут" },
    { value: 3300, label: "55 минут" },
    { value: 3600, label: "60 минут" },
    { value: 3900, label: "65 минут" },
    { value: 4200, label: "70 минут" },
    { value: 4500, label: "75 минут" },
    { value: 4800, label: "80 минут" },
    { value: 5100, label: "85 минут" },
    { value: 5400, label: "90 минут" },
    { value: 5700, label: "95 минут" },
    { value: 6000, label: "100 минут" },
    { value: 6300, label: "105 минут" },
    { value: 6600, label: "110 минут" },
    { value: 6900, label: "115 минут" },
    { value: 7200, label: "120 минут" },
  ],
  break: [
    { value: 60, label: "1 минута" },
    { value: 120, label: "2 минуты" },
    { value: 180, label: "3 минуты" },
    { value: 240, label: "4 минуты" },
    { value: 300, label: "5 минут" },
    { value: 360, label: "6 минут" },
    { value: 420, label: "7 минут" },
    { value: 480, label: "8 минут" },
    { value: 540, label: "9 минут" },
    { value: 600, label: "10 минут" },
    { value: 900, label: "15 минут" },
    { value: 1200, label: "20 минут" },
    { value: 1500, label: "25 минут" },
    { value: 1800, label: "30 минут" },
    { value: 2100, label: "35 минут" },
    { value: 2400, label: "40 минут" },
    { value: 2700, label: "45 минут" },
    { value: 3000, label: "50 минут" },
    { value: 3300, label: "55 минут" },
    { value: 3600, label: "60 минут" },
  ],
  longBreak: [
    { value: 300, label: "5 минут" },
    { value: 600, label: "10 минут" },
    { value: 900, label: "15 минут" },
    { value: 1200, label: "20 минут" },
    { value: 1500, label: "25 минут" },
    { value: 1800, label: "30 минут" },
    { value: 2100, label: "35 минут" },
    { value: 2400, label: "40 минут" },
    { value: 2700, label: "45 минут" },
    { value: 3000, label: "50 минут" },
    { value: 3300, label: "55 минут" },
    { value: 3600, label: "60 минут" },
  ],
  cycles: [
    { value: 1, label: "1 сессия" },
    { value: 2, label: "2 сессии" },
    { value: 3, label: "3 сессии" },
    { value: 4, label: "4 сессии" },
    { value: 5, label: "5 сессий" },
    { value: 6, label: "6 сессий" },
    { value: 7, label: "7 сессий" },
    { value: 8, label: "8 сессий" },
    { value: 9, label: "9 сессий" },
    { value: 10, label: "10 сессий" },
  ],
};

export const customSettingsOptions = {
  pomodoro: [
    { value: 300, label: "5 минут" },
    { value: 600, label: "10 минут" },
    { value: 900, label: "15 минут" },
    { value: 1200, label: "20 минут" },
    { value: 1500, label: "25 минут" },
    { value: 1800, label: "30 минут" },
    { value: 2100, label: "35 минут" },
    { value: 2400, label: "40 минут" },
    { value: 2700, label: "45 минут" },
    { value: 3000, label: "50 минут" },
    { value: 3300, label: "55 минут" },
    { value: 3600, label: "60 минут" },
    { value: 3900, label: "65 минут" },
    { value: 4200, label: "70 минут" },
    { value: 4500, label: "75 минут" },
    { value: 4800, label: "80 минут" },
    { value: 5100, label: "85 минут" },
    { value: 5400, label: "90 минут" },
    { value: 5700, label: "95 минут" },
    { value: 6000, label: "100 минут" },
    { value: 6300, label: "105 минут" },
    { value: 6600, label: "110 минут" },
    { value: 6900, label: "115 минут" },
    { value: 7200, label: "120 минут" },
  ],
  break: [
    { value: 60, label: "1 минута" },
    { value: 120, label: "2 минуты" },
    { value: 180, label: "3 минуты" },
    { value: 240, label: "4 минуты" },
    { value: 300, label: "5 минут" },
    { value: 360, label: "6 минут" },
    { value: 420, label: "7 минут" },
    { value: 480, label: "8 минут" },
    { value: 540, label: "9 минут" },
    { value: 600, label: "10 минут" },
    { value: 900, label: "15 минут" },
    { value: 1200, label: "20 минут" },
    { value: 1500, label: "25 минут" },
    { value: 1800, label: "30 минут" },
    { value: 2100, label: "35 минут" },
    { value: 2400, label: "40 минут" },
    { value: 2700, label: "45 минут" },
    { value: 3000, label: "50 минут" },
    { value: 3300, label: "55 минут" },
    { value: 3600, label: "60 минут" },
  ],
  longBreak: [
    { value: 300, label: "5 минут" },
    { value: 600, label: "10 минут" },
    { value: 900, label: "15 минут" },
    { value: 1200, label: "20 минут" },
    { value: 1500, label: "25 минут" },
    { value: 1800, label: "30 минут" },
    { value: 2100, label: "35 минут" },
    { value: 2400, label: "40 минут" },
    { value: 2700, label: "45 минут" },
    { value: 3000, label: "50 минут" },
    { value: 3300, label: "55 минут" },
    { value: 3600, label: "60 минут" },
  ],
  cycles: [
    { value: 1, label: "1 сессия" },
    { value: 2, label: "2 сессии" },
    { value: 3, label: "3 сессии" },
    { value: 4, label: "4 сессии" },
    { value: 5, label: "5 сессий" },
    { value: 6, label: "6 сессий" },
    { value: 7, label: "7 сессий" },
    { value: 8, label: "8 сессий" },
    { value: 9, label: "9 сессий" },
    { value: 10, label: "10 сессий" },
  ],
};
