/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, MouseEvent, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { Theme } from "../../theme/types";
import { ReactComponent as Arrow } from "../../assets/tooltip_arrow.svg";
import useStorageValue from "../../hooks/useStorageValue";
import { StorageField } from "../../types/bridge";
import clsx from "clsx";

const styles = makeStyles((theme: Theme) => ({
  root: {
    width: (props) => (props.width ? props.width : 187),
    height: (props) => (props.height ? props.height : 81),
    borderRadius: 10,
    zIndex: 95,
    boxShadow:
      "0px 4px 32px rgba(0, 0, 0, 0.16), 0px 0px 4px rgba(0, 0, 0, 0.04)",
    position: "absolute",
    top: (props) => props.top,
    background: "linear-gradient(180deg, #6CC584 0%, #71CC8B 100%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    opacity: (props: {
      toolTip: boolean;
      width: number;
      height: number;
      top: string;
    }) => (props.toolTip ? 1 : 0),
    visibility: (props: { toolTip: boolean }) =>
      props.toolTip ? "visible" : "hidden",
    "& svg": {
      width: 34,
      height: 8,
      bottom: "100%",
      position: "absolute",
      color: "#6CC584",
    },
  },
  text: {
    color: "white",
    overflow: "hidden",
    textAlign: "center",
    fontWeight: theme.typography.fontWeightMedium,
    padding: "0px 18px",
  },
  center: {
    transform: "translateX(-50%)",
    left: "50%",
  },
}));
const ToolTip = ({
  text,
  width,
  center,
  height,
  top,
  type,
}: {
  text: string;
  width: number;
  height: number;
  top: string;
  type: StorageField;
  center?: boolean;
}) => {
  const [toolTip, setToolTip] = useState<boolean>(true);
  const classes = styles({ toolTip, width, height, top });

  const setTodoTip = useStorageValue(StorageField.TodoToolTipSeen)[1];
  const setMainTip = useStorageValue(StorageField.MainToolTipSeen)[1];

  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: TouchEvent | MouseEvent | any) => {
    e.stopPropagation();
    type === StorageField.TodoToolTipSeen && setToolTip(false);
    type === StorageField.TodoToolTipSeen && setTodoTip(true);
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside, false);
    window.addEventListener("touchstart", handleClickOutside, false);

    return () => {
      window.removeEventListener("click", handleClickOutside, false);
      window.removeEventListener("touchstart", handleClickOutside, false);
    };
  }, []);

  return (
    <div
      className={clsx(classes.root, center && classes.center)}
      ref={ref}
      onClick={() => {
        setToolTip(false);
        setMainTip(true);
      }}
    >
      <p className={classes.text}>{text}</p>
      <Arrow />
    </div>
  );
};

export default React.memo(ToolTip);
