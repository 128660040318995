import { ofType, unionize, UnionOf } from "unionize";
import { unionizeConfig } from "../utils";
import {
  SESSION_TYPE,
  SESSION_STATUS,
  Todo,
  TimerSettings,
  SavedValues,
  TimerSetting,
} from "bridge";

export const timerActions = unionize(
  {
    setTimerTicking: ofType<boolean>(),
    updateSettings: ofType<{
      newSettings: Partial<TimerSetting>;
      activeTodo: Todo;
    }>(),
    reset: {},
    countdown: {},
    updateCurrentTime: ofType<number>(),
    setSessionType: ofType<SESSION_TYPE>(),
    setSessionStatus: ofType<SESSION_STATUS>(),
    setSavedValues: ofType<{
      startedAt: number;
      currentTime: number;
      currentCycle: number;
      sessionType: SESSION_TYPE;
    }>(),
    setCurrentCycle: ofType<number>(),
    setCompletedCycles: ofType<boolean>(),
    setToolTip: ofType<boolean>(),
    setCurrentSettings: ofType<"classic" | "custom">(),
    setAmountCycles: ofType<{ amount: number }>(),
    setCompletedPoints: ofType<{ points?: number; reset?: boolean }>(),
    updateLongBreakCyclesLeft: ofType<{ reset: boolean }>(),
  },
  unionizeConfig
);

type TimerAction = UnionOf<typeof timerActions>;

export interface TimerReducerState {
  /**
   * Настройки таймеров: по умолчанию и кастомные
   */
  settings: TimerSettings;
  /**
   * Идентификатор пресета
   */
  currentSettings: keyof TimerSettings;
  /**
   * Состояние обратного отсчёта
   */
  ticking: boolean;
  /**
   * Оставшееся время в секундах
   */
  currentTime: number;
  /**
   * Оставшееся количество циклов
   */
  currentCycle: number;
  /**
   * Оставшееся количество смен циклов до большого перерыва
   */
  longBreakCyclesLeft: number;
  /**
   * Сохранённое состояние таймер на время последнего запуска
   */
  savedValues: SavedValues;
  /**
   * Тип текущей сессии: POMODORO, BREAK, LONG BREAK
   */
  sessionType: SESSION_TYPE;
  /**
   * Статус текущего цикла: START, RUNNING, PAUSE
   */
  sessionStatus: SESSION_STATUS;
  /**
   * Количство рабочих циклов на один таймер, 0 - бесконечно
   */
  amountCycles: number;
  /**
   * Выполненное количество рабочих циклов в данном таймере
   */
  completedCycles: number;
  /**
   * Количество поинтов для отображения анимации сбора яблок
   */
  completedPoints: number;
  /**
   * Выполнено - невыполнено
   */
  completed: boolean;
}

const initialState: TimerReducerState = {
  settings: {
    classic: {
      label: "Классический",
      timerTime: 25 * 60,
      breakTime: 5 * 60,
      longBreakCycles: 3,
      longBreakTime: 15 * 60,
      timerAutoStart: false,
      breaksAutoStart: false,
    },
    custom: {
      label: "Пользовательский",
      timerTime: 25 * 60,
      breakTime: 5 * 60,
      longBreakCycles: 3,
      longBreakTime: 15 * 60,
      timerAutoStart: false,
      breaksAutoStart: false,
    },
  },
  currentSettings: "classic",
  ticking: false,
  currentTime: 25 * 60,
  currentCycle: 3,
  longBreakCyclesLeft: 0,
  savedValues: {
    startedAt: 0,
    currentTime: 0,
    currentCycle: 0,
    sessionType: SESSION_TYPE.POMODORO,
  },
  sessionType: SESSION_TYPE.POMODORO,
  sessionStatus: SESSION_STATUS.START,
  amountCycles: 5,
  completedCycles: 0,
  completed: false,
  completedPoints: 0,
};

/**
 * Редьюсер который отвечает за таймер.
 * @param {Timer} state
 * @param {TimerAction} action
 * @returns {string[]}
 */
function timerReducer(
  state: TimerReducerState = initialState,
  action: TimerAction
) {
  return timerActions.match(action, {
    setTimerTicking: (status) => {
      const sessionStatus = status
        ? SESSION_STATUS.RUNNING
        : SESSION_STATUS.PAUSE;
      return { ...state, ticking: status, sessionStatus };
    },
    updateSettings: ({ newSettings }): TimerReducerState => {
      const currentSessionType = state.sessionType;
      const timeAim =
        currentSessionType === SESSION_TYPE.POMODORO
          ? "timerTime"
          : currentSessionType === SESSION_TYPE.BREAK
          ? "breakTime"
          : "longBreakTime";
      const newCurrentCycle =
        newSettings.longBreakCycles! - state.longBreakCyclesLeft <= 0
          ? 1
          : state.sessionType === SESSION_TYPE.LONGBREAK
          ? newSettings.longBreakCycles
          : newSettings.longBreakCycles! - state.longBreakCyclesLeft;
      return {
        ...state,
        settings: {
          ...state.settings,
          [state.currentSettings]: {
            ...state.settings[state.currentSettings],
            ...newSettings,
          },
        },
        currentTime: newSettings[timeAim]
          ? newSettings[timeAim]!
          : state.currentTime,
        currentCycle: newSettings.longBreakCycles
          ? newCurrentCycle!
          : state.currentCycle,
        longBreakCyclesLeft: newSettings.longBreakCycles
          ? 0
          : state.longBreakCyclesLeft,
        sessionStatus: newSettings[timeAim]
          ? SESSION_STATUS.START
          : state.sessionStatus,
      };
    },
    reset: (): TimerReducerState => ({
      ...state,
      ticking: false,
      settings: state.settings,
      currentTime: state.settings[state.currentSettings].timerTime,
      sessionStatus: SESSION_STATUS.START,
      sessionType: SESSION_TYPE.POMODORO,
      savedValues: {
        ...state.savedValues,
        startedAt: 0,
        currentTime: 0,
        currentCycle: 0,
        sessionType: SESSION_TYPE.POMODORO,
      },
      currentCycle: state.settings[state.currentSettings].longBreakCycles,
      completedCycles: 0,
    }),
    countdown: () => {
      return { ...state, currentTime: state.currentTime - 1 };
    },
    updateCurrentTime: (newCurrentTime) => ({
      ...state,
      currentTime: newCurrentTime,
    }),
    setSessionType: (type: SESSION_TYPE) => {
      const shouldUpdateCycle =
        state.sessionType === SESSION_TYPE.POMODORO && state.currentCycle > 0;
      return {
        ...state,
        sessionType: type,
        currentCycle: shouldUpdateCycle
          ? state.currentCycle - 1
          : state.sessionType === SESSION_TYPE.LONGBREAK
          ? state.settings[state.currentSettings].longBreakCycles
          : state.currentCycle,
        longBreakCyclesLeft:
          state.sessionType === SESSION_TYPE.LONGBREAK
            ? 0
            : state.longBreakCyclesLeft,
      };
    },
    setSessionStatus: (status: SESSION_STATUS) => ({
      ...state,
      sessionStatus: status,
    }),
    setSavedValues: ({
      currentCycle,
      currentTime,
      startedAt,
      sessionType,
    }): TimerReducerState => ({
      ...state,
      savedValues: {
        startedAt,
        currentTime,
        currentCycle,
        sessionType,
      },
    }),
    setCurrentCycle: (newCycle): TimerReducerState => ({
      ...state,
      currentCycle: newCycle,
    }),
    setCompletedCycles: (reset): TimerReducerState => {
      return {
        ...state,
        completedCycles: reset ? 0 : state.completedCycles + 1,
      };
    },
    setToolTip: (status) => ({
      ...state,
      toolTips: { settings: status },
    }),
    setCurrentSettings: (newCurrent: "classic" | "custom") => {
      const timeAim =
        state.sessionType === SESSION_TYPE.POMODORO
          ? "timerTime"
          : state.sessionType === SESSION_TYPE.BREAK
          ? "breakTime"
          : "longBreakTime";
      return {
        ...state,
        currentSettings: newCurrent,
        currentTime: state.settings[newCurrent][timeAim],
        currentCycle: state.settings[newCurrent].longBreakCycles,
      };
    },

    setAmountCycles: ({ amount }) => ({
      ...state,
      amountCycles: amount,
    }),

    setCompletedPoints: ({ points, reset }) => ({
      ...state,
      completedPoints: reset ? 0 : state.completedPoints + points!,
    }),

    updateLongBreakCyclesLeft: ({ reset }) => ({
      ...state,
      longBreakCyclesLeft: reset ? 0 : state.longBreakCyclesLeft + 1,
    }),
    default: (): TimerReducerState => state,
  });
}

export default timerReducer;
