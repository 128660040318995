/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useMemo, useState, useEffect } from "react";
import makeStyles from "@material-ui/styles/makeStyles";

import OnboardingView from "./views/OnboardingView";
import TimerView from "./views/TimerView";
import SettingsView from "./views/SettingsView";
import ProfileView from "./views/ProfileView";
import Registration from "./Registration";
import Epic, { EpicView } from "./Epic";
import BottomNavigation, { BottomNavigationItem } from "./BottomNavigation";

// @ts-ignore
import VideoOutline from "@vkontakte/icons/dist/28/video_outline";
// @ts-ignore
import CheckOutline from "@vkontakte/icons/dist/28/check_circle_outline";
// @ts-ignore
import SettingsOutline from "@vkontakte/icons/dist/28/settings_outline";
// @ts-ignore
import InfoOutline from "@vkontakte/icons/dist/28/info_outline";

import useStorageValue from "../hooks/useStorageValue";
import useSelector from "../hooks/useSelector";
import useActions from "../hooks/useActions";
import { StorageField } from "../types/bridge";
import { layoutActions } from "../redux/reducers/layout";
import { Theme } from "../theme/types";
import AlertContextProvider from "./AlertContext";
import { GlobalStyleSheet } from "./GlobalStyleSheet/GlobalStyleSheet";
import Anime from "react-anime";

enum View {
  Timer = "timer",
  Profile = "profile",
  Settings = "settings",
  FAQ = "faq",
}

const styles = makeStyles((theme: Theme) => ({
  root: {
    fontFamily: theme.typography.fontFamilyTT,
    background: "white",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  anime: {
    width: "100%",
    height: "100%",
  },
}));

/**
 * Компонент, с которого начинается весь визуал и логика по отображению
 * необходимых элементов.
 * @type {React.NamedExoticComponent<object>}
 */
const App = memo(() => {
  const classes = styles();

  const [onboardingCompleted] = useStorageValue(
    StorageField.OnboardingCompleted
  );

  const { isRegistered } = useSelector((state) => ({
    isRegistered: state.user.isRegistered,
  }));
  const [activeView, setActiveView] = useState(View.Timer);

  const { setActiveModal } = useActions({
    setActiveModal: layoutActions.setActiveModal,
  });

  const setModal = (id: View) => {
    setActiveModal(id);
    setActiveView(id);
  };
  const navigationItems = useMemo(
    () => [
      { Icon: VideoOutline, title: "Игра", id: View.Timer },
      { Icon: CheckOutline, title: "Профиль", id: View.Profile },
      { Icon: SettingsOutline, title: "Рейтинг", id: View.Settings },
    ],
    []
  );
  const views = useMemo(
    () => [
      { id: View.Timer, Component: TimerView },
      { id: View.Profile, Component: ProfileView },
      { id: View.Settings, Component: SettingsView },
    ],
    []
  );

  useEffect(() => {
    document.body.className = classes.root;
  }, [onboardingCompleted]);

  if (!onboardingCompleted) {
    return <OnboardingView />;
  }

  if (!isRegistered) {
    return <Registration />;
  }

  // TODO: мы не используем Epic от ВКонтакте по той причине что он не
  //  поддерживает сохранение контента View при переключении активной стори.
  return (
    <>
      <AlertContextProvider>
        <GlobalStyleSheet />
        <Epic
          activeView={activeView}
          navigation={
            <BottomNavigation>
              {navigationItems.map(({ Icon, id }) => (
                <BottomNavigationItem
                  Icon={Icon}
                  active={id === activeView}
                  onClick={() => setModal(id)}
                  key={id}
                />
              ))}
              <a
                style={{ flex: "1 0 0" }}
                href="https://vk.com/@donetimer-kak-rabotat-s-done-timer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BottomNavigationItem Icon={InfoOutline} />
              </a>
            </BottomNavigation>
          }
        >
          {views.map(({ id, Component }) => {
            if (id === View.Timer) {
              return (
                <EpicView id={id} key={id} keepMounted={true}>
                  <div style={{ height: "100%", display: "flex" }}>
                    <Anime
                      duration={300}
                      opacity={[0, 1]}
                      easing={"easeInOutExpo"}
                    >
                      <Component />
                    </Anime>
                  </div>
                </EpicView>
              );
            } else
              return (
                <EpicView id={id} key={id}>
                  <Anime
                    duration={300}
                    opacity={[0, 1]}
                    easing={"easeInOutExpo"}
                  >
                    <Component />
                  </Anime>
                </EpicView>
              );
          })}
        </Epic>
      </AlertContextProvider>
    </>
  );
});

export default App;
