import { unionize, UnionOf, ofType } from "unionize";
import { unionizeConfig } from "../utils";
import { AddToHomeScreenDataInput } from "bridge";

export interface UserReducerState {
  fullName: string;
  isRegistered?: boolean;
  profileImageUrl: string | undefined;
  applesCount: number;
  notificationSettings: {
    isAllowedMessagesFromGroup: boolean | null;
    isAskedForAllowMessages: boolean | null;
    isWorkingWithServerNotification: boolean | null;
  };
  addToHomeScreenData: AddToHomeScreenDataInput;
}

export const userActions = unionize(
  {
    setUserRegistered: {},
    setApplesCount: ofType<number>(),
    setNotificationsSettings: ofType<{
      isAllowedMessagesFromGroup: boolean;
      isAskedForAllowMessages: boolean;
      isWorkingWithServerNotification: boolean;
    }>(),
  },
  unionizeConfig
);

type UserAction = UnionOf<typeof userActions>;

const initialState: UserReducerState = {
  fullName: "",
  profileImageUrl: "",
  applesCount: 0,
  notificationSettings: {
    isAllowedMessagesFromGroup: false,
    isAskedForAllowMessages: false,
    isWorkingWithServerNotification: false,
  },
  addToHomeScreenData: {
    isAddedToHomeScreen: false,
    isAskedAddToHomeScreen: false,
  },
};

/**
 * Редьюсер который отвечает за информацию о пользователе.
 * @param {StorageReducerState} state
 * @param {StorageAction} action
 * @returns {string[]}
 */
function userReducer(
  state: UserReducerState = initialState,
  action: UserAction
) {
  return userActions.match(action, {
    setUserRegistered: () => ({ ...state, isRegistered: true }),
    setApplesCount: (count) => ({
      ...state,
      applesCount: state.applesCount + count,
    }),
    setNotificationsSettings: ({
      isAllowedMessagesFromGroup,
      isAskedForAllowMessages,
      isWorkingWithServerNotification,
    }) => ({
      ...state,
      notificationSettings: {
        ...state.notificationSettings,
        isAllowedMessagesFromGroup,
        isAskedForAllowMessages,
        isWorkingWithServerNotification,
      },
    }),
    default: () => state,
  });
}

export default userReducer;
