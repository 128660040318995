/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Theme } from "../../theme/types";
import { ReactComponent as Edit } from "../../assets/edit.svg";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { ReactComponent as Watch } from "../../assets/watch.svg";
import { ReactComponent as Template } from "../../assets/template.svg";
import { CSSTransition } from "react-transition-group";

const styles = makeStyles((theme: Theme) => ({
  toolBar: {
    background: "#FFFFFF",
    border: "0.8px solid #EEEEEE",
    boxShadow: "-0.5px 1px 15px rgba(0, 0, 0, 0.11)",
    borderRadius: "20px",
    width: "80%",
    zIndex: 99,
    position: "absolute",
    right: 0,
    top: "0",
    overflow: "hidden",
    opacity: 0,
    transition: `opacity 150ms ease-in-out`,
  },
  toolBarItem: {
    display: "flex",
    alignItems: "center",
    padding: "12px 24px 12px 24px",
    cursor: "pointer",
    color: "#515151",
    fontSize: 16,
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightMedium,

    "&:active": {
      background: "#F8F8F8",
    },
    "&:last-child": {
      paddingTop: 12,
      paddingBottom: 17,
    },
    "&:first-child": {
      paddingTop: 17,
    },
    "& svg": {
      marginRight: 20,
      minWidth: 25,
      width: 23,
      height: 23,
      color: "#B5B5B5",
    },
    "& span": {
      height: 20,
      display: "block",
    },
  },
  disableOverflow: {
    overflowY: "hidden",
  },
}));

const useTransitionStyles = makeStyles({
  enter: {
    opacity: 0,
  },
  enterActive: {
    opacity: 1,
  },
  enterDone: {
    opacity: 1,
  },
  exit: {
    opacity: 1,
  },
  exitActive: {
    opacity: 0,
  },
  exitDone: {
    opacity: 0,
  },
});

const ToolBar = ({
  templateHandler,
  putOnMainHandler,
  setActiveTodoHandler,
  editHandler,
  deleteHandler,
  completed,
  isActive,
  fromMain,
  open,
  clickOutside,
}: {
  templateHandler: () => void;
  deleteHandler: () => void;
  putOnMainHandler: () => void;
  setActiveTodoHandler: () => void;
  editHandler: () => void;
  isActive: boolean;
  completed: boolean;
  fromMain: boolean;
  open: boolean;
  clickOutside: () => void;
}) => {
  const classes = styles();
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: TouchEvent | MouseEvent) => {
    e.stopPropagation();
    const target = e.target as HTMLElement;
    if (ref.current && !ref.current.contains(target)) {
      clickOutside();
    }
  };

  useEffect(() => {
    window.addEventListener("touchstart", handleClickOutside, false);
    window.addEventListener("click", handleClickOutside, false);

    return () => {
      window.removeEventListener("touchstart", handleClickOutside, false);
      window.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  return (
    <CSSTransition
      in={open}
      timeout={150}
      classNames={useTransitionStyles()}
      mountOnEnter={false}
      unmountOnExit={true}
    >
      <div className={classes.toolBar} ref={ref}>
        {completed && (
          <div
            className={classes.toolBarItem}
            onClick={() => {
              templateHandler();
            }}
          >
            <Template />
            <div>
              <span>Создать копию</span>
            </div>
          </div>
        )}
        {isActive && fromMain && (
          <div
            className={classes.toolBarItem}
            onClick={() => {
              putOnMainHandler();
              setActiveTodoHandler();
            }}
          >
            <Watch />
            <div>
              <span>Отложить</span>
            </div>
          </div>
        )}
        {!completed && (
          <div
            className={classes.toolBarItem}
            onClick={() => {
              editHandler();
            }}
          >
            <Edit />
            <div>
              <span>Редактировать</span>
            </div>
          </div>
        )}

        <div
          className={classes.toolBarItem}
          onClick={() => {
            deleteHandler();
          }}
        >
          <Delete />
          <div>
            <span>Удалить</span>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default React.memo(ToolBar);
