/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles } from "@material-ui/core/styles";
import useSelector from "../../hooks/useSelector";
import { ReduxState } from "../../redux/types";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { SESSION_TYPE, SESSION_STATUS } from "bridge";
import TimerButton from "../TimerButton";
import Tree from "../Tree";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ToolTip from "../ToolTip";
import { StorageField } from "../../types/bridge";
import useStorageValue from "../../hooks/useStorageValue";

const theme = createMuiTheme({
  transitions: {
    create: () => "300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
});

const styles = makeStyles({
  root: {
    position: "relative",
    marginBottom: 30,
  },
  outerCycle: (props: { progressColor: string; platform: any }) => ({
    color: props.progressColor,

    "& svg circle": {
      strokeLinecap: "round",
    },
  }),
  innerCycle: (props: { progressColor: string }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    color: props.progressColor,
    opacity: 0.2,
  }),
  tree: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "80%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    position: "absolute",
    bottom: -26,
    left: "50%",
    transform: "translateX(-50%)",
  },
  resetButton: {
    position: "absolute",
    top: -5,
    right: -5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:active": {
      opacity: 0.7,
    },
  },
  resetButtonTransparent: {
    opacity: 0.7,
  },
});
const TimerProgress = ({
  time,
  sessionType,
  completedCycles,
}: {
  time: number;
  sessionType: SESSION_TYPE;
  completedCycles: number;
}) => {
  const timeAim =
    sessionType === SESSION_TYPE.POMODORO
      ? "timerTime"
      : sessionType === SESSION_TYPE.BREAK
      ? "breakTime"
      : "longBreakTime";
  const { width } = useWindowDimensions();
  const progressColor =
    sessionType === SESSION_TYPE.POMODORO ? "#FF745B" : "#6CC584";

  const platform = useSelector((state) => state.config.launchParams.platform);

  const classes = styles({ progressColor, platform });

  const settings = useSelector((state: ReduxState) => state.timer.settings);
  const currentSettings = useSelector(
    (state: ReduxState) => state.timer.currentSettings
  );

  const sessionStatus = useSelector(
    (state: ReduxState) => state.timer.sessionStatus
  );

  const [completed, setCompleted] = useState(0);
  const [fullValue, setFullValue] = useState(
    settings[currentSettings][timeAim]
  );

  const [mainToolTip] = useStorageValue(StorageField.MainToolTipSeen);

  useEffect(() => {
    if (sessionType === SESSION_TYPE.POMODORO) {
      setFullValue(settings[currentSettings].timerTime);
    } else if (sessionType === SESSION_TYPE.LONGBREAK) {
      setFullValue(settings[currentSettings].longBreakTime);
    } else {
      setFullValue(settings[currentSettings].breakTime);
    }
  }, [sessionType, time, settings]);

  useEffect(() => {
    const percent = (time / fullValue) * 100;

    if (sessionStatus === SESSION_STATUS.START) {
      setCompleted(0);
    } else setCompleted(percent);
  }, [time, sessionStatus, completed]);

  const marginPercent = (width * 20) / 100;
  const circularProgressSize = () => {
    if (width > 500) {
      if (platform === "desktop_web") {
        return 280;
      } else return 400;
    } else return width - marginPercent;
  };
  const timerButtonSize = 56;

  const visibleProgressFraction =
    1 - timerButtonSize / circularProgressSize() / Math.PI;

  const circularValue = completed * visibleProgressFraction;
  const circularRotateDeg = -90 - 180 * visibleProgressFraction;

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <CircularProgress
          className={classes.outerCycle}
          style={{ transform: `rotate(${circularRotateDeg}deg)` }}
          color={"inherit"}
          thickness={2.1}
          size={circularProgressSize()}
          variant="static"
          value={circularValue}
        />
        <CircularProgress
          className={classes.innerCycle}
          color={"inherit"}
          thickness={2.1}
          size={circularProgressSize()}
          variant="static"
          value={100}
        />
        {!mainToolTip && (
          <ToolTip
            text="Красный цвет таймера &mdash; работа, зеленый &mdash; перерыв"
            center
            width={210}
            height={99}
            top={"14px"}
            type={StorageField.MainToolTipSeen}
          />
        )}
        <div className={classes.tree}>
          <Tree
            progress={completed}
            completedCycles={completedCycles}
            sessionType={sessionType}
            currentTime={time}
          />
        </div>
        <TimerButton
          className={classes.button}
          type="start"
          session={sessionType}
        />
        {sessionStatus === SESSION_STATUS.PAUSE && (
          <TimerButton
            className={classes.resetButton}
            type="reset"
            session={sessionType}
          />
        )}
      </div>
    </MuiThemeProvider>
  );
};

export default React.memo(TimerProgress);
