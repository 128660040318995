/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ReactComponent as TreeSVG } from "../../assets/tree.base64.svg";
import appleSVG from "../../assets/apple.base64.svg";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { SESSION_TYPE } from "bridge";
import { Theme } from "../../theme/types";
import useActions from "../../hooks/useActions";
import { timerActions } from "../../redux/reducers/timer";
import { userActions } from "../../redux/reducers/user";
import { Todo } from "../../redux/reducers/todo";
import useSelector from "../../hooks/useSelector";

interface TreeProps {
  className?: string;
  progress: number;
  completedCycles: number;
  sessionType: SESSION_TYPE;
  currentTime: number;
}

const styles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    "& .tree": {
      animation: `$shaking 0.8s`,
      transformOrigin: "50% 50%",
    },
  },
  tree: ({
    currentStage,
    apples,
    isShowLastStage,
    progress,
  }: {
    currentStage: number;
    apples: number[];
    isShowLastStage: boolean;
    progress: number;
  }) => ({
    "&  .all": {
      opacity: 0,
    },
    "& .apple": {
      transition: "all 0.5s",
      opacity: 0,
    },
    [`& .apple-${apples.length + 1}-${
      isShowLastStage ? 1 : currentStage === 1 ? 2 : currentStage
    }`]: {
      opacity: 1,
    },
    [`& ${apples.map((_, key) => `.apple-${key + 1}-${1}, `).join("")}`]: {
      opacity: 1,
    },
  }),
  reward: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    justifyContent: "center",
    animation: `$up 1.2s`,
    animationTimingfunction: "ease-in-out",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& > div": {
      marginRight: 5,
      fontSize: "30px",
      color: "green",
      fontFamily: theme.typography.fontFamilyTT,
    },
    "& > img": {
      width: "32px",
    },
  },

  "@keyframes shaking": {
    "0%": { transform: "translate(2px, 1px) rotate(0deg)" },
    "10%": { transform: "translate(-1px, -2px) rotate(-1deg)" },
    "20%": { transform: "translate(-3px, 0px) rotate(1deg)" },
    "30%": { transform: "translate(0px, 2px) rotate(0deg)" },
    "40%": { transform: "translate(1px, -1px) rotate(1deg)" },
    "50%": { transform: "translate(-1px, 2px) rotate(-1deg)" },
    "60%": { transform: "translate(-3px, 1px) rotate(0deg)" },
    "70%": { transform: "translate(2px, 1px) rotate(-1deg)" },
    "80%": { transform: "translate(-1px, -1px) rotate(1deg)" },
    "90%": { transform: "translate(2px, 2px) rotate(0deg)" },
    "100%": { transform: "translate(1px, -2px) rotate(-1deg)" },
  },
  "@keyframes up": {
    "0%": {
      opacity: 1,
      transform: "translate(-50%, -50%)",
    },

    "100%": {
      transform: "translate(-50%, -150px)",
      opacity: 0,
    },
  },
}));
const Tree = ({
  className,
  progress,
  completedCycles,
  sessionType,
  currentTime,
}: TreeProps) => {
  const { setCompletedCycles } = useActions({
    setCompletedCycles: timerActions.setCompletedCycles,
  });

  const { setCompletedPoints } = useActions({
    setCompletedPoints: timerActions.setCompletedPoints,
  });
  const { setApplesCount } = useActions({
    setApplesCount: userActions.setApplesCount,
  });

  const todos = useSelector((state) => state.todos);
  const active = todos.find((todo: Todo) => todo.active === true);

  const completedPoints = useSelector(
    (state: any) => state.timer.completedPoints
  );

  const [animation, setAnimation] = useState(false);
  const [rewardAnimation, setRewardAnimation] = useState(false);
  const [prevCycle, setPrevCycle] = useState<any>(0);
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //@ts-ignore
  const apples = [...Array(completedCycles).keys()];

  //кол-во стадий яблока
  const stages = 6;
  //процент одной стадии
  const stage = 100 / stages;
  const currentStage =
    progress < 100 && sessionType === SESSION_TYPE.POMODORO
      ? Math.ceil(progress / stage)
      : 0;

  const isShowLastStage =
    currentTime === 0 && sessionType === SESSION_TYPE.POMODORO;
  const classes = styles({ currentStage, apples, isShowLastStage, progress });

  useEffect(() => {
    if (
      completedCycles >= 5 &&
      sessionType !== SESSION_TYPE.POMODORO &&
      !active
    ) {
      setCompletedPoints({ points: completedCycles });
      setCompletedCycles(true);
      setApplesCount(5);
    }

    if (completedPoints > 0) {
      setPrevCycle(completedPoints);
      setRewardAnimation(true);
      setCompletedPoints({ reset: true });
    }
  }, [completedPoints, completedCycles]);

  return (
    <>
      <TreeSVG
        onClick={() => setAnimation(true)}
        className={clsx(animation && classes.root, classes.tree, className)}
        onAnimationEnd={() => setAnimation(false)}
      />
      {rewardAnimation && (
        <div
          className={classes.reward}
          onAnimationEnd={() => setRewardAnimation(false)}
        >
          <div>{`+${prevCycle}`}</div>
          <img src={appleSVG} alt="яблоко" />
        </div>
      )}
    </>
  );
};

export default React.memo(Tree);
