import { ofType, unionize, UnionOf } from "unionize";
import { unionizeConfig } from "../utils";
//import {Todo} from "bridge"

export interface Todo {
  _id: string;
  userId: number;
  active: boolean;
  onMain: boolean;
  title: string;
  description?: string;
  amountCycles: number;
  completedCycles: number;
  completed: boolean;
  createdAt?: number;
  completedAt?: number;
  index: number;
}

export const todoActions = unionize(
  {
    addTodo: ofType<{
      _id: string;
      title: string;
      amountCycles: number;
      index: number;
      fromMain: boolean;
    }>(),
    toggleTodo: ofType<{ _id: string; index: number }>(),
    editTodo: ofType<{
      _id: string;
      title?: string;
      amountCycles?: number;
      completedCycles?: number;
      index?: number;
    }>(),
    deleteTodo: ofType<{ _id: string; index: number }>(),
    setActiveTodo: ofType<{ _id: string }>(),
    putOnMainTodo: ofType<{ _id: string }>(),
    updateIndexes: ofType<{
      sourceIndex: number;
      destIndex: number;
      type: "plus" | "minus";
      _id?: string;
    }>(),
    setTodoCompletedCycles: ofType<{ _id: string; completedCycles: number }>(),
  },
  unionizeConfig
);

type TodoAction = UnionOf<typeof todoActions>;

export type Todos = Todo[];
const initialState: Todos = [];

/**
 * Редьюсер который отвечает за todo.
 * @param {Timer} state
 * @param {TimerAction} action
 * @returns {string[]}
 */

function todoReducer(state: Todo[] = initialState, action: TodoAction) {
  return todoActions.match(action, {
    addTodo: ({ _id, title, amountCycles, index, fromMain }): Todos => {
      return [
        ...state,
        {
          _id,
          title,
          completed: false,
          active: fromMain,
          onMain: fromMain,
          amountCycles,
          completedAt: 0,
          index,
          userId: 1,
          completedCycles: 0,
        },
      ];
    },
    toggleTodo: ({ _id, index }) => {
      const plannedTodos = state.filter((todo) => !todo.completed);
      const completedTodos = state.filter((todo) => todo.completed);
      const completedIndexes = completedTodos.map((todo) => todo.index);
      const maxCompletedIndex = Math.max(...completedIndexes);

      const currentTodo = state.find((todo) => todo._id === _id);
      const completed = currentTodo?.completed;
      const secondActiveTodo = plannedTodos
        .sort((a, b) => a.index - b.index)
        .filter((todo) => todo.active !== true && todo._id !== _id)[0];

      return state.map((todo) => {
        if (todo._id === _id) {
          return {
            ...todo,
            completed: !todo.completed,
            index: todo.completed
              ? plannedTodos.length > 0
                ? plannedTodos.length
                : 0
              : completedTodos.length > 0
              ? maxCompletedIndex + 1
              : 0,
          };
        } else if (
          currentTodo?.active &&
          secondActiveTodo &&
          todo._id === secondActiveTodo._id &&
          !completed
        ) {
          return {
            ...todo,
            active: !todo.active,
            onMain: !todo.onMain,
          };
        } else {
          return {
            ...todo,
            index:
              !completed && !todo.completed
                ? todo.index > index
                  ? todo.index - 1
                  : todo.index
                : todo.index,
          };
        }
      });
    },
    editTodo: ({ _id, title, amountCycles, completedCycles, index }) => {
      return state.map((todo) =>
        todo._id === _id
          ? {
              ...todo,
              title: title ? title : todo.title,
              amountCycles: amountCycles ? amountCycles : todo.amountCycles,
              completedCycles: completedCycles
                ? completedCycles
                : todo.completedCycles,
              index: typeof index !== "undefined" ? index : todo.index,
            }
          : todo
      );
    },
    deleteTodo: ({ _id, index }) => {
      const currentTodo = state.find((todo) => todo._id === _id);
      const isCompleted = currentTodo?.completed;

      if (isCompleted) {
        return state.filter((todo) => todo._id !== _id);
      } else {
        return state
          .filter((todo) => todo._id !== _id)
          .map((todo) =>
            todo.index > index ? { ...todo, index: todo.index - 1 } : todo
          );
      }
    },

    setActiveTodo: ({ _id }) => {
      return state.map((todo) =>
        todo._id === _id ? { ...todo, active: !todo.active } : todo
      );
    },
    putOnMainTodo: ({ _id }) =>
      state.map((todo) =>
        todo._id === _id ? { ...todo, onMain: !todo.onMain } : todo
      ),

    updateIndexes: ({ sourceIndex, destIndex, type, _id }) => {
      return state.map((todo) => {
        if (type === "plus") {
          if (
            sourceIndex >= todo.index &&
            todo.index >= destIndex &&
            todo._id !== _id &&
            !todo.completed
          ) {
            return { ...todo, index: todo.index + 1 };
          } else {
            return todo;
          }
        } else {
          if (
            todo.index <= destIndex &&
            todo.index >= sourceIndex &&
            todo._id !== _id &&
            todo.completed !== true
          ) {
            return { ...todo, index: todo.index - 1 };
          } else {
            return todo;
          }
        }
      });
    },

    setTodoCompletedCycles: ({ _id, completedCycles }) =>
      state.map((todo) =>
        todo._id === _id ? { ...todo, completedCycles } : todo
      ),

    default: () => state,
  });
}

export default todoReducer;
