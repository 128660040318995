import { SESSION_TYPE, TimerSetting } from "bridge";

export function chooseSessionType(type: SESSION_TYPE, cycles: number) {
  const isLongBreak = cycles === 1;
  switch (type) {
    case SESSION_TYPE.POMODORO:
      return isLongBreak ? SESSION_TYPE.LONGBREAK : SESSION_TYPE.BREAK;
    case SESSION_TYPE.BREAK:
      return SESSION_TYPE.POMODORO;
    case SESSION_TYPE.LONGBREAK:
      return SESSION_TYPE.POMODORO;
    default:
      return SESSION_TYPE.POMODORO;
  }
}

/**
  Функция, которая определяет состояние таймера, если включена настройка
   автоматического запуска.
 */
export function getCurrentSessionPosition(
  difTime: number,
  settings: TimerSetting,
  sessionType: SESSION_TYPE | null | undefined,
  currentCycle: number | undefined
) {
  let newCurrentTime = 0;
  let newSessionType = sessionType;
  let newCycle = currentCycle!;
  console.log(difTime);

  const getPosition = (
    difTime: number,
    sessionType: SESSION_TYPE | null | undefined
  ) => {
    const nextType = chooseSessionType(sessionType!, newCycle!);
    console.log(sessionType, nextType, newCycle);

    if (nextType === SESSION_TYPE.BREAK) {
      newCycle = newCycle - 1;
      newCurrentTime = settings.breakTime + difTime;
      if (newCurrentTime < 0 && !settings.breaksAutoStart) {
        newSessionType = SESSION_TYPE.BREAK;
        newCurrentTime = settings.breakTime;
      } else {
        newCurrentTime < 0
          ? getPosition(newCurrentTime, SESSION_TYPE.BREAK)
          : (newSessionType = SESSION_TYPE.BREAK);
      }
    } else if (nextType === SESSION_TYPE.LONGBREAK) {
      newCycle = newCycle - 1;
      newCurrentTime = settings.longBreakTime + difTime;
      if (newCurrentTime < 0 && !settings.breaksAutoStart) {
        newSessionType = SESSION_TYPE.LONGBREAK;
        newCurrentTime = settings.longBreakTime;
      } else {
        newCurrentTime < 0
          ? getPosition(newCurrentTime, SESSION_TYPE.LONGBREAK)
          : (newSessionType = SESSION_TYPE.LONGBREAK);
      }
    } else if (nextType === SESSION_TYPE.POMODORO) {
      if (newCycle === 0) newCycle = currentCycle!;
      newCurrentTime = settings.timerTime + difTime;
      if (newCurrentTime < 0 && !settings.timerAutoStart) {
        newSessionType = SESSION_TYPE.POMODORO;
        newCurrentTime = settings.timerTime;
      } else {
        newCurrentTime < 0
          ? getPosition(newCurrentTime, SESSION_TYPE.POMODORO)
          : (newSessionType = SESSION_TYPE.POMODORO);
      }
    } else return;

    return { newCurrentTime, newSessionType, newCycle };
  };

  if (difTime < 0 && sessionType) {
    return getPosition(difTime, sessionType);
  }
}
