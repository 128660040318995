import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as CheckIcon } from "../assets/check-icon.svg";
import clsx from "clsx";

const styles = makeStyles({
  hiddenInput: {
    border: 0,
    clip: " rect(0 0 0 0)",
    clippath: " inset(50%)",
    height: "1px",
    margin: "-1px",
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    whiteSpace: "nowrap",
    width: 1,
  },

  icon: {
    transition: "all 0.1s",
    opacity: 0,
  },
  iconDefault: {
    height: 22,
    opacity: 1,
    transition: "opaicity 0.2s",
  },
  completed: {
    opacity: 1,
  },

  anime: {
    animation: `$shrink-bounce 0.2s forwards ease-in-out`,
    "& > svg": {
      opacity: (props: { completed: boolean }) => (!props.completed ? 1 : null),
    },
  },
  "@keyframes shrink-bounce": {
    "0%": {
      transform: "scale(1)",
    },
    "33%": {
      transform: "scale(.85)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
});

const CheckBox = ({ className, onClick, completed }: any) => {
  const [animation, setAnimation] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false);

  const classes = styles({ completed });

  return (
    <>
      <input
        className={classes.hiddenInput}
        type="checkbox"
        defaultChecked={completed}
      ></input>
      <div
        onClick={() => {
          console.log("click", animation);
          setShowIcon(true);
          setAnimation(!animation);
        }}
        className={clsx(className, animation && classes.anime)}
        onAnimationEnd={() => {
          setAnimation(false);
          onClick();
        }}
      >
        {(completed || showIcon) && (
          <CheckIcon className={clsx(classes.iconDefault)} />
        )}
      </div>
    </>
  );
};

export default React.memo(CheckBox);
