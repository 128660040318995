/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "../../theme/types";
import useAlertContext from "../AlertContext/useAlertContext";
import { InsetsInterface } from "../../types/custom-types";
import { Transition } from "react-transition-group";
import useInsets from "../../hooks/useInsets";
import { ReactComponent as ArrowSVG } from "../../assets/arrow.svg";
import { ReactComponent as CloseSvg } from "../../assets/close.svg";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import useActions from "../../hooks/useActions";
import { todoActions, Todo } from "../../redux/reducers/todo";
import useSelector from "../../hooks/useSelector";
import { ReduxState } from "../../redux/types";
import { useMutation } from "@apollo/react-hooks";
import {
  createTodoMutation,
  CreateTodoMutation,
  EditTodoMutation,
  editTodoMutation,
} from "bridge";
import { Spinner } from "@vkontakte/vkui";
import { anime } from "react-anime";

const styles = makeStyles((theme: Theme) => ({
  overlay: {
    position: "fixed",
    bottom: (props: {
      insets: InsetsInterface;
      translate: number;
      width: number;
      appleQtyToArray: number[];
    }) => (props.insets.bottom! > 150 ? 0 : props.insets.bottom),
    left: 0,
    width: "100%",
    height: "100%",
    background: "grey",
    zIndex: 1001,
    display: "flex",
    alignItems: "flex-end",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    width: "100%",

    background: "white",
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
  },
  disableBodyScroll: {
    overflow: "hidden",
  },
  header: {
    color: "#4FC16F",
    fontSize: 22,
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightBold,
    padding: "16px 55px 15px 21px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.06)",
    position: "relative",
    "& svg": {
      position: "absolute",
      right: 0,
      top: 0,
      padding: "24px 22px 22px 21px",
    },
  },
  todoName: {
    fontFamily: theme.typography.fontFamilyTT,
    margin: "16px 21px 15px 21px",
    borderBottom: "1.5px solid #F3F3F3",
    "& label": {
      display: "flex",
      flexDirection: "column-reverse",
      color: "#B4B4B4",
      fontSize: 16,
      fontWeight: theme.typography.fontWeightRegular,
      paddingBottom: 14,
    },
    "& input": {
      marginTop: 2,
      border: "none",
      height: 30,
      color: "#515151",
      fontSize: 20,
      fontWeight: theme.typography.fontWeightBold,
      outline: "none",
      padding: 0,
      borderRadius: 0,
      "&::placeholder": {
        color: "#515151",
        opacity: 0.65,
      },
    },
  },
  applesSelector: {
    padding: "0px 21px 15px 21px",
  },
  applesSelectorTitle: {
    color: "#B4B4B4",
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
    paddingBottom: 14,
  },
  slider: {
    position: "relative",
    overflow: "hidden",
  },
  sliderContent: {
    width: "100%",
    transform: (props) => `translateX(${props.translate}px)`,
    transition: "all ease-out 0.4s",
    display: "flex",
    fontFamily: theme.typography.fontFamilyTT,
    padding: "10px 0",
    margin: "-10px 0",
  },
  apple: ({ appleQtyToArray }) => {
    return {
      width: 27,
      minWidth: 27,
      height: 27,
      minHeight: 27,
      background: "linear-gradient(180deg, #FF6F55 0%, #FF785F 100%)",
      borderRadius: "50%",
      padding: 1,
      cursor: "pointer",
      opacity: 0.35,
      marginRight: 19,
      /*  "&:not(:nth-last-child(2))": {
        marginRight: 19,
      }, */
      [`&${[5, 4, 3, 2, 1]
        .map((num, i: number) => `[data-value='${i + 1}'], &`)
        .join("")
        .slice(0, -3)}`]: {
        opacity: 0.35,
      },
      [`&${
        appleQtyToArray.length <= 0
          ? "[data-value='0']"
          : appleQtyToArray
              .map((num, i: number) => `[data-value='${i + 1}'], &`)
              .join("")
              .slice(0, -3)
      }`]: {
        opacity: 1,
      },
    };
  },
  number: ({ appleQtyToArray }) => ({
    color: "#FF745B",
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
    padding: 6,
    opacity: 0.35,
    cursor: "pointer",

    "&:not(:nth-last-child(1))": {
      marginRight: 25,
    },
    "&:nth-child(2)": {
      marginLeft: 16,
    },

    [`&${[6, 7, 8, 9, 10]
      .map((num, i: number) => `[data-value='${num}'], &`)
      .join("")
      .slice(0, -3)}`]: {
      opacity: 0.35,
    },

    [`&${
      appleQtyToArray.length > 5
        ? `[data-value='${appleQtyToArray.length}']`
        : "[data-value='0']"
    }`]: {
      opacity: 1,
    },
  }),
  slide: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 100%",
    position: "relative",
    overflow: "hidden",
  },
  button: {
    padding: "19px 22px 30px 22px",

    "& button": {
      background: "linear-gradient(180deg, #6CC584 0%, #71CC8B 100%)",
      borderRadius: 15,
      border: "none",
      outline: "none",
      width: "100%",
      color: "#FFFFFF",
      cursor: "pointer",
      fontSize: 19,
      fontFamily: theme.typography.fontFamilyTT,
      fontWeight: theme.typography.fontWeightMedium,
      height: 45,
      "&:active": {
        opacity: 0.6,
      },
    },
  },
}));

const AddTodo = ({
  type,
  _id,
  title,
  amountCycles,
  fromMain = false,
  doneCycles,
}: {
  type: "edit" | "add" | "template";
  _id?: string;
  title?: string;
  amountCycles?: number;
  fromMain?: boolean;
  doneCycles?: number;
}) => {
  const insets = useInsets();

  const [createTodoRemote, { loading, data }] = useMutation<
    CreateTodoMutation,
    CreateTodoMutation.Arguments
  >(createTodoMutation);

  const [
    editTodoRemote,
    { loading: editLoading, data: editData },
  ] = useMutation<EditTodoMutation, EditTodoMutation.Arguments>(
    editTodoMutation
  );

  const { addTodo } = useActions({
    addTodo: todoActions.addTodo,
  });

  const { editTodo } = useActions({
    editTodo: todoActions.editTodo,
  });

  const { width } = useWindowDimensions();

  const [contentVisible, setContentVisible] = useState(false);
  const [todoName, setTodoName] = useState(title ? title : "");
  const [activeIndex, setActiveIndex] = useState(0);
  const [translate, setTranslate] = useState(0);
  const [appleQty, setAppleQty] = useState(amountCycles ? amountCycles : 1);

  const [position, setPosition] = useState<{
    startX: number;
    currentX: number;
    direction: string;
    startTime: any;
    allowedTime: number;
  }>({
    startX: 0,
    currentX: 0,
    direction: "none",
    startTime: null,
    allowedTime: 100,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //@ts-ignore
  const appleQtyToArray = [...Array(appleQty).keys()];

  const classes = styles({ insets, translate, width, appleQtyToArray });

  const todos = useSelector((state: ReduxState) => state.todos);

  const { closeAlert } = useAlertContext();
  const ref = useRef<HTMLDivElement>(null);
  const padding = 42;

  const nextSlide = () => {
    if (activeIndex !== 1) {
      setTranslate((activeIndex + 1) * width * -1 + padding);
      setActiveIndex(activeIndex + 1);
      setPosition({
        ...position,
        startX: 0,
        currentX: 0,
        startTime: null,
      });
    }
    return;
  };

  const prevSlide = () => {
    if (activeIndex === 1) {
      setTranslate((activeIndex - 1) * width);
      setActiveIndex(activeIndex - 1);
      setPosition({
        ...position,
        startX: 0,
        currentX: 0,
        startTime: null,
      });
    }
    return;
  };

  const handleClickOutside = (e: TouchEvent | MouseEvent) => {
    const target = e.target as HTMLElement;

    if (target.contains(ref.current)) {
      /*  closeAlert(); */
      setContentVisible(false);
    }
  };

  const touchStartHandler = (e: React.TouchEvent<HTMLDivElement>) => {
    const obj = e.touches[0];
    setPosition({
      ...position,
      startX: obj.clientX,
      startTime: new Date().getTime(),
    });
  };

  const touchMoveHandler = (e: React.TouchEvent<HTMLDivElement>) => {
    const obj = e.touches[0];
    setPosition({ ...position, currentX: obj.clientX });
  };

  const touchEndHandler = (e: React.TouchEvent<HTMLDivElement>) => {
    const elapsedTime = new Date().getTime() - position.startTime;

    if (
      Math.abs(position.startX - position.currentX) > 50 &&
      position.currentX !== 0 &&
      elapsedTime >= position.allowedTime
    ) {
      const direction = position.startX > position.currentX ? "left" : "right";

      if (direction === "right") {
        prevSlide();
      }
      if (direction === "left") {
        nextSlide();
      }
      setPosition({
        ...position,
        direction,
        startX: 0,
        currentX: 0,
        startTime: null,
      });
    }
  };

  useEffect(() => {
    const element = document.body as any;
    element.setAttribute("style", "position: fixed; width: 100%;");
    document.body.classList.add(classes.disableBodyScroll);

    setContentVisible(true);
    return () => {
      document.body.classList.remove(classes.disableBodyScroll);
      element.removeAttribute("style");
      setContentVisible(false);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("touchstart", handleClickOutside);
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("touchstart", handleClickOutside);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const duration = 150;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  const appleClickHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const target = e.target as HTMLDivElement;
    const value = target.dataset.value ? parseInt(target.dataset.value) : null;
    if (doneCycles && value && doneCycles > value) {
      return;
    }
    setAppleQty(value ? value : 0);
  };

  const getApples = () => {
    const apples = [1, 2, 3, 4, 5];

    return apples.map((i) => (
      <div
        key={i}
        className={classes.apple}
        data-value={i}
        onClick={(e) => appleClickHandler(e)}
      ></div>
    ));
  };

  const getNumbers = () => {
    const numbers = [6, 7, 8, 9, 10];

    return numbers.map((num, i) => (
      <div
        key={i}
        className={classes.number}
        data-value={num}
        onClick={(e) => appleClickHandler(e)}
      >
        {num}
      </div>
    ));
  };

  const plannedTodos = todos.filter((todo: Todo) => todo.completed === false);
  const newIndex = plannedTodos.length > 0 ? plannedTodos.length : 0;

  const addTodoHandler = () => {
    if (todoName.trim().length < 1) {
      return;
    } else
      createTodoRemote({
        variables: {
          input: {
            title: todoName,
            amountCycles: appleQty,
            index: newIndex,
            active: fromMain,
            onMain: fromMain,
            description: "",
          },
        },
      });
  };

  const editTodoHandler = () => {
    if (_id) {
      editTodoRemote({
        variables: {
          input: {
            _id,
            title: todoName,
            amountCycles: appleQty,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (editData?.editTodo && _id) {
      editTodo({ _id, title: todoName, amountCycles: appleQty });
      setContentVisible(false);
    }
  }, [editData]);

  useEffect(() => {
    if (!loading && data?.createTodo) {
      addTodo({
        _id: data.createTodo,
        title: todoName,
        amountCycles: appleQty,
        index: newIndex,
        fromMain,
      });
      setContentVisible(false);
    }
  }, [loading, data]);

  return (
    <Transition
      in={contentVisible}
      timeout={duration}
      unmountOnExit
      onExited={() => closeAlert()}
      onEntering={(node: HTMLElement) =>
        anime({
          targets: node,
          opacity: [0, 1],
          duration: 80,
          easing: "easeInOutExpo",
        })
      }
    >
      {(state: "entering" | "entered" | "exiting" | "exited") => (
        <div
          style={{ ...defaultStyle, ...transitionStyles[state] }}
          ref={ref}
          className={classes.overlay}
        >
          <div className={classes.content}>
            <div className={classes.header}>
              {type === "add" || type === "template"
                ? "Создание задачи"
                : "Редактирование задачи"}
              <CloseSvg onClick={() => setContentVisible(false)} />
            </div>
            <div className={classes.todoName}>
              <label>
                <input
                  type="text"
                  placeholder="Введите название задачи"
                  value={todoName}
                  onChange={(e) =>
                    setTodoName(
                      e.target.value.length < 50
                        ? e.target.value.replace(/ {1,}/g, " ")
                        : todoName.replace(/ {1,}/g, " ")
                    )
                  }
                ></input>
                Название задачи
              </label>
            </div>
            <div className={classes.applesSelector}>
              <div className={classes.applesSelectorTitle}>
                Количество рабочих сессий
              </div>
              <div className={classes.slider}>
                <div
                  className={classes.sliderContent}
                  onTouchStart={(e) => touchStartHandler(e)}
                  onTouchMove={(e) => touchMoveHandler(e)}
                  onTouchEnd={(e) => touchEndHandler(e)}
                >
                  <div className={classes.slide}>
                    <ArrowSVG
                      style={{
                        position: "absolute",
                        right: 0,
                        padding: "10px 10px",
                        margin: "0px -10px",
                        cursor: "pointer",
                      }}
                      onClick={() => nextSlide()}
                    />
                    {getApples()}
                  </div>
                  <div className={classes.slide}>
                    <ArrowSVG
                      style={{
                        position: "absolute",
                        left: 0,
                        transform: "rotate(180deg)",
                        padding: "10px 10px",
                        margin: "0px -10px",
                        cursor: "pointer",
                      }}
                      onClick={() => prevSlide()}
                    />

                    {getNumbers()}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.button}>
              <button
                disabled={!todoName.replace(/\s/g, "").length}
                style={{
                  opacity: `${!todoName.replace(/\s/g, "").length ? 0.3 : 1}`,
                }}
                onClick={() =>
                  type === "add" || type === "template"
                    ? addTodoHandler()
                    : editTodoHandler()
                }
              >
                {loading || editLoading ? (
                  <Spinner style={{ color: "white" }} size="regular" />
                ) : type === "add" || type === "template" ? (
                  "Добавить в список"
                ) : (
                  "Сохранить"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default React.memo(AddTodo);
