import React, {memo} from 'react';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import Anime from 'react-anime';
import {ReactComponent as Logo} from '../../assets/logo.svg';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  logo: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoImg: {
    width: '50%',
  },
});

/**
 * Вью которая отображается в случае, когда приложение загружается.
 * @type {React.NamedExoticComponent<object>}
 */
const AppLoadingView = memo(() => {
  const mc = useStyles();

  return (
    <div className={mc.root}>
      <Anime
        duration={500}
        opacity={[0.1, 1]}
        rotate={[360]}
        scale={[0.1, 1]}
        easing={'easeInOutSine'}
      >
        <div className={mc.logo}>
          <Logo
            className={mc.logoImg}
          />
        </div>
      </Anime>
    </div>
  );
});

export default AppLoadingView;
