export interface Config {
  apiBaseUrl: string;
  group_id: number;
}

// Здесь происходит загрузка конфига на основе переменных внешней среды. Стоит
// не забывать что только переменные, начинающиеся с REACT_APP будут загружены
// react-scripts
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const group_id = Number(process.env.REACT_APP_VK_GROUP_ID);

if (!apiBaseUrl) {
  throw new Error('API base url not passed');
}

if (!group_id) {
  throw new Error('Group ID not passed');
}

const config: Config = {
  apiBaseUrl,
  group_id,
};

export default config;
