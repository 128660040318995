import { Theme } from "./types";

const defaultTheme: Theme = {
  palette: {
    green: "#4FC16F",
    tomato: "#FF654A",
  },

  typography: {
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontDemiBold: 600,
    fontWeightBold: 700,
    fontFamily: "-apple-system, Roboto, Helvetica Neue, Arial",
    fontFamilyTT: '"TT Commons", -apple-system, Roboto, Helvetica Neue, Arial',
    timeSize: "3.4rem",
  },
};

export default defaultTheme;
