import React, { memo, useState } from "react";
import alertContext from "./context";

const AlertContextProvider: React.FC = memo(({ children }) => {
  const [alert, setAlert] = useState<React.ReactNode | null>(null);
  const closeAlert = () => setAlert(null);

  return (
    <alertContext.Provider value={{ closeAlert, openAlert: setAlert }}>
      {alert}
      {children}
    </alertContext.Provider>
  );
});

export default AlertContextProvider;
