import { createContext } from "react";

export interface AlertContext {
  openAlert(alert: React.ReactElement): void;
  closeAlert(): void;
}

const alertContext = createContext<AlertContext>({
  openAlert: () => null,
  closeAlert: () => null,
});

export default alertContext;
