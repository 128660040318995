import { ttCommonsDemiBold, ttCommonsMedium, ttCommonsRegular } from "./fonts";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "../../theme/types";
import { memo } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  "@global": {
    "@font-face": [
      {
        fontFamily: "TT Commons",
        fontStyle: "normal",
        fontWeight: 400,
        src: `url("${ttCommonsRegular}") format("woff")`,
      },
      {
        fontFamily: "TT Commons",
        fontStyle: "normal",
        fontWeight: 500,
        src: `url("${ttCommonsMedium}") format("woff")`,
      },
      {
        fontFamily: "TT Commons",
        fontStyle: "normal",
        fontWeight: 600,
        src: `url("${ttCommonsDemiBold}") format("woff")`,
      },
    ],
  },
}));

export const GlobalStyleSheet = memo(() => {
  useStyles();
  return null;
});
