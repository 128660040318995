import {ReduxState} from './types';
import {Store, createStore, combineReducers} from 'redux';
// import {persistStore, persistReducer} from 'redux-persist';
// import {Persistor} from 'redux-persist/lib/types';
// import storage from 'redux-persist/lib/storage';
import {devToolsEnhancer} from 'redux-devtools-extension';

import configReducer from './reducers/config';
import storageReducer from './reducers/storage';
import layoutReducer from './reducers/layout';
import userReducer from './reducers/user';
import timerReducer from './reducers/timer';
import todoReducer from './reducers/todo';

// const persistConfig = {
//   key: "tracker",
//   storage,
// };

const reducers = combineReducers<ReduxState>({
  config: configReducer,
  storage: storageReducer,
  user: userReducer,
  layout: layoutReducer,
  timer: timerReducer,
  todos: todoReducer,
});

// const persistedReducer = persistReducer(persistConfig, reducers);

function createReduxStore(
  state?: Partial<ReduxState>,
): {
  store: Store<ReduxState>;
  // persistor: Persistor;
} {
  const store = createStore(reducers, state, devToolsEnhancer({}));
  // const persistor = persistStore(store);

  return {
    store,
    // persistor,
  };
}

export default createReduxStore;
