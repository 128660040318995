import React, { useState, useRef } from "react";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import CheckBox from "../../sub-components/CheckBox";
import { Theme } from "../../theme/types";
import clsx from "clsx";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as Dots } from "../../assets/dots.svg";
import { ReactComponent as ActiveWatch } from "../../assets/watch__active.svg";
import circleSVG from "../../assets/circle.base64.svg";

import useAlertContext from "../AlertContext/useAlertContext";
import AddTodo from "../modals/AddTodo";
import useActions from "../../hooks/useActions";
import { todoActions, Todo as TTodo } from "../../redux/reducers/todo";
import { userActions } from "../../redux/reducers/user";
import { timerActions } from "../../redux/reducers/timer";
import { useMutation } from "@apollo/react-hooks";
import {
  DeleteTodoMutation,
  deleteTodoMutation,
  ToggleTodoMutation,
  toggleTodoMutation,
  ToggleActiveTodoMutation,
  toggleActiveTodoMutation,
} from "bridge";
import useSelector from "../../hooks/useSelector";
import ConfirmResetAlert from "../alerts/ConfirmResetAlert";
import ToolTip from "../ToolTip";
import useStorageValue from "../../hooks/useStorageValue";
import { StorageField } from "../../types/bridge";
import ToolBar from "../ToolBar";

const styles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    cursor: "pointer",
    opacity: 1,
    transition: `opacity 300ms ease-in-out`,
  },
  timerQty: {
    display: "inline-block",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    background: `url(${circleSVG})`,
    opacity: 0.37,
  },
  doneQty: {
    display: "inline-block",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    background: `url(${circleSVG})`,
    opacity: 1,
  },

  container: {
    display: "flex",
    borderRadius: "20px",
    boxShadow: "0px 0.85px 8px rgba(0, 0, 0, 0.05)",
    marginBottom: "16px",
    minHeight: 60,
    width: "100%",
    padding: "8px 0px 8px 18px",
    boxSizing: "border-box",
    alignItems: "center",
    wordBreak: "break-word",
    background: "white",
    border: (props) =>
      props.isActive && !props.fromMain
        ? "1.4px solid #4FC16F"
        : "0.85px solid #EEEEEE",
    position: "relative",
    overflow: "hidden",
    animation: "all 0.4s",
    whiteSpace: (props) =>
      props.completed || props.fromMain ? "nowrap" : "unset",
    opacity: (props) => (props.completed ? 0.7 : 1),
    "&:active": {
      background: "#F8F8F8",
    },
    transition: "all 0.4s",
  },
  opacity: {},
  checkbox: {
    display: "flex",
    width: 28,
    height: 28,
    minWidth: 28,
    background: "white",
    borderRadius: "50%",
    border: "1.4px solid #4FC16F",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 17,
    cursor: "pointer",
    boxSizing: "border-box",
  },
  dots: {
    position: "absolute",
    right: 0,
    padding: "0px 22px",
    height: "100%",
    cursor: "pointer",
    color: (props: {
      toolBar: boolean;
      openTodo: boolean;
      isActive: boolean;
      fromMain: boolean;
      completed: boolean;
      areThereEmojis: () => boolean;
    }) =>
      props.toolBar
        ? "#515151"
        : props.isActive && !props.fromMain
        ? "#4FC16F"
        : "#E1E1E1",
  },
  activeWatchSVG: {
    position: "absolute",
    right: 17,
    padding: "19px 22px 19px 10px",
    cursor: "pointer",
    color: "#4FC16F",
  },

  center: {
    paddingRight: (props) =>
      props.isActive && !props.fromMain ? "70px" : "45px",
    marginBottom: 2,
  },
  todoTitle: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: "#515151",
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 16,
    lineHeight: (props) => (props.areThereEmojis() ? "unset" : "20px"),
  },
  appleBarNum: {
    display: "inline-flex",
    alignItems: "center",
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 14,
    color: "#FF6F55",
    lineHeight: 1,
  },
  appleSeparator: {
    fontFamily: theme.typography.fontFamilyTT,
  },
  anime: {
    animation: `$fade 0.3s`,
  },
  "@keyframes fade": {
    "0%": {
      opacity: "1",
    },

    "100%": {
      opacity: "0",
    },
  },
}));

const emojiRegex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;

const useTransitionStyles = makeStyles({
  enter: {
    opacity: 0,
  },
  enterActive: {
    opacity: 1,
  },
  enterDone: {
    opacity: 1,
  },
  exit: {
    opacity: 1,
  },
  exitActive: {
    opacity: 0,
  },
  exitDone: {
    opacity: 1,
  },
});

const Todo = ({
  label,
  timerQty,
  doneQty,
  isActive,
  completed,
  _id,
  fromMain,
  activeTodo,
  index,
}: {
  _id: string;
  label: string;
  timerQty: number;
  doneQty: number;
  isActive: boolean;
  completed: boolean;
  activeTodo: TTodo | false;
  /** 
   Переменная, которая служит для определения, отрисовался компонент на главной 
   или в блоке с задачами(для выделения todo цветом)
   isMain, в свою очередь, показывает, находится ли todo на главной в очереди
    */
  fromMain: boolean;
  index: number;
}) => {
  const [toolBar, setToolBar] = useState<boolean>(false);
  const [openTodo, setOpenTodo] = useState<boolean>(true);
  const [action, setAction] = useState<"toggle" | "delete" | "setAside" | null>(
    null
  );

  let tempDoneQty = doneQty;

  const [deleteTodoRemote] = useMutation<
    DeleteTodoMutation,
    DeleteTodoMutation.Arguments
  >(deleteTodoMutation);

  const [toolTip] = useStorageValue(StorageField.TodoToolTipSeen);

  const [toggleActiveRemote] = useMutation<
    ToggleActiveTodoMutation,
    ToggleActiveTodoMutation.Arguments
  >(toggleActiveTodoMutation);

  const [toggleTodoRemote] = useMutation<
    ToggleTodoMutation,
    ToggleTodoMutation.Arguments
  >(toggleTodoMutation);

  const { setApplesCount } = useActions({
    setApplesCount: userActions.setApplesCount,
  });

  const { toggleTodo } = useActions({
    toggleTodo: todoActions.toggleTodo,
  });

  const { deleteTodo } = useActions({
    deleteTodo: todoActions.deleteTodo,
  });

  const { putOnMainTodo } = useActions({
    putOnMainTodo: todoActions.putOnMainTodo,
  });

  const { setActiveTodo } = useActions({
    setActiveTodo: todoActions.setActiveTodo,
  });

  const { setCompletedPoints } = useActions({
    setCompletedPoints: timerActions.setCompletedPoints,
  });

  const completedPoints = useSelector((state) => state.timer.completedPoints);

  const areThereEmojis = () => {
    if (label.match(emojiRegex)) {
      return true;
    }
    return false;
  };

  const classes = styles({
    toolBar,
    openTodo,
    isActive,
    fromMain,
    completed,
    areThereEmojis,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //@ts-ignore
  const timers = [...Array(timerQty).keys()];

  const { openAlert } = useAlertContext();

  const toolBarClickedOutsideHandler = () => {
    setToolBar(false);
  };
  const editHandler = () => {
    setToolBar(false);
    openAlert(
      <AddTodo
        type="edit"
        _id={_id}
        title={label}
        amountCycles={timerQty}
        doneCycles={doneQty}
      />
    );
  };

  const templateHandler = () => {
    setToolBar(false);
    openAlert(
      <AddTodo
        type="template"
        _id={_id}
        title={label}
        amountCycles={timerQty}
      />
    );
  };

  const deleteHandler = () => {
    setToolBar(false);
    openAlert(
      <ConfirmResetAlert
        mainContent={"Вы действительно хотите удалить эту задачу?"}
        header={"Удаление задачи"}
        btnLabel={"Удалить"}
        onConfirm={() => {
          deleteTodoRemote({
            variables: {
              input: {
                _id,
              },
            },
          });
          deleteTodo({ _id, index });
        }}
      />
    );
  };

  const todoRef = useRef<any>(null);

  const putOnMainHandler = () => {
    if (!completed) {
      if (activeTodo && activeTodo._id !== _id) {
        putOnMainTodo({ _id: activeTodo._id });
      }
      putOnMainTodo({ _id });
      setToolBar(false);
    }
  };

  const setActiveTodoHandler = (remote = true) => {
    if (!completed) {
      if (activeTodo && activeTodo._id !== _id) {
        setActiveTodo({ _id: activeTodo._id });
      }
      if (remote) {
        toggleActiveRemote({
          variables: {
            input: {
              _id,
              active: !isActive,
            },
          },
        });
      }
      setActiveTodo({ _id });

      setToolBar(false);
    }
  };

  const handleCheckboxClick = () => {
    //возвращаем обратно в запланированные, вычитаем яблоки
    const afterAnimate = () => {
      if (completed) {
        toggleTodoRemote({
          variables: {
            input: {
              _id,
              completed: !completed,
            },
          },
        });
        toggleTodo({ _id, index: index });
        if (doneQty > 0) {
          setApplesCount(doneQty * -1);
          if (completedPoints > 0) {
            setCompletedPoints({ points: doneQty * -1 });
          }
        }
      } else if (!completed) {
        toggleTodoRemote({
          variables: {
            input: {
              _id,
              completed: !completed,
            },
          },
        });

        toggleTodo({ _id, index: index });
        if (isActive) {
          setActiveTodoHandler(false);
          putOnMainHandler();
        }
        if (doneQty > 0) {
          setApplesCount(doneQty);
          setCompletedPoints({ points: doneQty });
        }
      }
    };

    afterAnimate();
  };

  const getApplesBar = () => {
    if (timerQty > 5 && doneQty > 0) {
      return (
        <div className={classes.appleBarNum}>
          <div style={{ marginRight: 4 }}>{doneQty}</div>
          <div
            style={{
              width: 10,
              minWidth: 10,
              height: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className={classes.doneQty}></div>
          </div>
          <div
            className={classes.appleSeparator}
            style={{ color: "#E1E1E1", padding: "0 6px" }}
          >
            /
          </div>
          <div style={{ marginRight: 4, opacity: 0.35 }}>{timerQty}</div>
          <div
            style={{
              width: 10,
              minWidth: 10,
              height: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className={classes.timerQty}></div>
          </div>
        </div>
      );
    } else if (timerQty > 5 && doneQty < 1) {
      return (
        <div className={classes.appleBarNum}>
          <div style={{ marginRight: 4, opacity: 0.35, lineHeight: 1 }}>
            {timerQty}
          </div>
          <div
            style={{
              width: 10,
              height: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: 7,
            }}
          >
            <div className={classes.timerQty}></div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            height: 15,
            alignItems: "center",
          }}
        >
          {timers.map((i) => {
            --tempDoneQty;
            const isDone = tempDoneQty >= 0;
            return (
              <div
                key={i}
                style={{
                  width: 10,
                  height: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 7,
                }}
              >
                <div
                  className={clsx(classes.timerQty, isDone && classes.doneQty)}
                ></div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <CSSTransition
      in={openTodo}
      timeout={300}
      classNames={useTransitionStyles()}
      mountOnEnter={false}
      unmountOnExit={false}
      onExited={() => {
        if (action === "toggle") {
          handleCheckboxClick();
        } else if (action === "delete") {
          deleteHandler();
        }

        setOpenTodo(true);
        setAction(null);
      }}
    >
      <div className={clsx(classes.root)}>
        <div ref={todoRef} className={clsx(classes.container)}>
          <CheckBox
            onClick={() => {
              /* handleCheckboxClick(); */
              setAction("toggle");
              setOpenTodo(!openTodo);
            }}
            completed={completed}
            className={classes.checkbox}
          />
          <div
            className={classes.center}
            style={{ width: "100%", overflow: "hidden" }}
            onClick={() => {
              if (!fromMain) {
                putOnMainHandler();
                setActiveTodoHandler();
              }
            }}
          >
            <div className={classes.todoTitle}>{label}</div>
            {getApplesBar()}
          </div>
          {isActive && !fromMain && (
            <ActiveWatch className={classes.activeWatchSVG} />
          )}

          <Dots
            className={classes.dots}
            onClick={(e) => {
              e.stopPropagation();
              setToolBar(!toolBar);
            }}
          />
        </div>

        <ToolBar
          isActive={isActive}
          completed={completed}
          fromMain={fromMain}
          open={toolBar}
          clickOutside={toolBarClickedOutsideHandler}
          templateHandler={templateHandler}
          deleteHandler={deleteHandler}
          putOnMainHandler={putOnMainHandler}
          setActiveTodoHandler={setActiveTodoHandler}
          editHandler={editHandler}
        />

        {index === 0 && !toolTip && !isActive && !completed && (
          <ToolTip
            text={
              "Нажмите на задачу, чтобы вынести ее на главный экран приложения"
            }
            width={200}
            height={100}
            top={"75%"}
            type={StorageField.TodoToolTipSeen}
          />
        )}
      </div>
    </CSSTransition>
  );
};

export default React.memo(Todo);
