import React from "react";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import moment from "moment";
import "moment-duration-format";
import useSelector from "../../hooks/useSelector";
import { ReduxState } from "../../redux/types";
import { Theme } from "../../theme/types";
import { SESSION_STATUS } from "bridge";

const styles = makeStyles((theme: Theme) => ({
  time: (props: { sessionStatus: SESSION_STATUS }) => ({
    fontSize: 50,
    height: "50px",
    display: "flex",
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightMedium,
    opacity: props.sessionStatus === SESSION_STATUS.PAUSE ? 0.3 : 1,
    transition: "opacity 0.2s",
  }),
  timeNumbers: {
    color: "#515151",
    width: 100,
    "&:first-child": {
      textAlign: "right",
    },
    "&:nth-child(3)": {
      textAlign: "left",
    },
  },
  timeSeparator: {
    lineHeight: "54px",
    color: "#B0B0B0",
    margin: "0 3px",
  },
}));

const TimeDisplay = () => {
  const currentTime = useSelector(
    (state: ReduxState) => state.timer.currentTime
  );
  const sessionStatus = useSelector(
    (state: ReduxState) => state.timer.sessionStatus
  );
  const classes = styles({ sessionStatus });

  const duration = moment.duration(currentTime, "seconds");
  //@ts-ignore
  const formatedCurrentTime = duration.format(
    `${currentTime < 60 ? "00" : "mm"}:ss`
  );

  const length = formatedCurrentTime.split("").length;

  const minutes = formatedCurrentTime
    .split("")
    .slice(0, length - 3)
    .join("");
  const seconds = formatedCurrentTime
    .split("")
    .slice(length - 2, length)
    .join("");

  return (
    <div className={classes.time}>
      <div className={classes.timeNumbers}>{minutes}</div>
      <div className={classes.timeSeparator}>:</div>
      <div className={classes.timeNumbers}>
        {currentTime < 0 ? "00" : seconds}
      </div>
    </div>
  );
};

export default React.memo(TimeDisplay);
