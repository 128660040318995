import React, { useRef } from "react";
import ProfileHeader from "../ProfileHeader";
import { makeStyles } from "@material-ui/styles";
import { ReactComponent as AddBtn } from "../../assets/add.svg";
import useAlertContext from "../AlertContext/useAlertContext";
import AddTodo from "../modals/AddTodo";
import useInsets from "../../hooks/useInsets";
import { InsetsInterface } from "../../types/custom-types";
import useSelector from "../../hooks/useSelector";
import { ReduxState } from "../../redux/types";
import TodosContainer from "../TodosContainer";
import AppHeader from "../AppHeader";
import { Todo } from "../../redux/reducers/todo";

const styles = makeStyles({
  panel: {
    "& .Panel__in": {
      paddingBottom: 0,
    },
  },
  root: {
    background: "white",
  },
  header: {
    height: "52px",
    display: "flex",
    fontSize: 30,
    alignItems: "flex-end",
    fontFamily: '"TT Commons", -apple-system, Roboto, Helvetica Neue, Arial',
    fontWeight: 700,
    marginLeft: 21,
    justifycontent: "center",
    color: "#4FC16F",
  },
  vkHeader: {
    "& .PanelHeader--android .PanelHeader-left-in": {
      paddingLeft: "0 !important",
    },
    "& .PanelHeader__in .PanelHeader-left-in": {
      paddingLeft: "0 !important",
    },
    "&  .PanelHeader__in .PanelHeader-left-in:not(:empty)": {
      paddingLeft: 0,
    },
  },
  container: {
    position: "relative",
    paddingBottom: (props) => props.insets.bottom! + 140,
    paddingTop: (props) => props.insets.top! + 54,
  },
  separator: {
    "& .Separator__in": {
      background: "#F4F4F4",
      height: 1.5,
      marginLeft: 21,
    },
  },
  addBtn: {
    position: "fixed",
    bottom: (props: { insets: InsetsInterface }) => 55 + props.insets.bottom!,
    right: 13,
    cursor: "pointer",
    zIndex: 999,
    opacity: 1,
    visibility: "visible",
    transition: "all 0.4s",
  },
});
const ProfileView = () => {
  const insets = useInsets();
  const { openAlert } = useAlertContext();

  const classes = styles({ insets });

  const todos = useSelector((state: ReduxState) => state.todos) as Todo[];

  const addTodoHandler = () => {
    openAlert(<AddTodo type="add" />);
  };

  const scrollTarget = useRef<any>(null);

  /**
   * определяем следующую задачу после тика первой задачи с главного экрана */

  return (
    <>
      <AppHeader title={"Задачи"} />
      <div className={classes.container} ref={scrollTarget}>
        <ProfileHeader />
        <TodosContainer
          title={"Запланированные"}
          todosQty={4}
          todosTimeSum={120}
          todos={todos}
          completed={false}
        />
        <TodosContainer
          title={"Выполненные"}
          todosQty={3}
          todosTimeSum={60}
          todos={todos}
          completed={true}
        />
        <div className={classes.addBtn} onClick={() => addTodoHandler()}>
          <AddBtn />
        </div>
      </div>
    </>
  );
};

export default React.memo(ProfileView);
