import React from 'react';
import VKAlert, { AlertProps } from '@vkontakte/vkui/dist/components/Alert/Alert';
import { makeStyles } from '@material-ui/core';
import c from 'classnames';
import useAlertContext from '../AlertContext/useAlertContext';

const styles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    zIndex: 1001,
  },
});

const Alert: React.FC<AlertProps> = ({ className, ...alertProps }) => {
  const classes = styles();
  const { closeAlert } = useAlertContext();

  return <VKAlert className={c(classes.root, className)} onClose={closeAlert} {...alertProps} />;
};

export default Alert;
