import React from "react";
import Alert from "../Alert";

interface ConfirmResetAlertProps {
  onConfirm(): void;
  mainContent?: string;
  btnType?: "destructive" | "default" | "cancel";
  btnLabel?: string;
  btnQty?: 1 | 2;
  header?: string;
}

const ConfirmResetAlert: React.FC<ConfirmResetAlertProps> = ({
  onConfirm,
  mainContent,
  btnType,
  btnLabel,
  btnQty = 2,
  header = "Подтвердите действие",
}) => {
  return (
    <Alert
      actionsLayout="vertical"
      actions={
        btnQty === 2
          ? [
              {
                title: btnLabel || "Сбросить",
                autoclose: true,
                action: onConfirm,
                mode: btnType || "destructive",
              },

              {
                title: "Отмена",
                mode: "cancel",
                autoclose: true,
              },
            ]
          : [
              {
                title: btnLabel || "Сбросить",
                autoclose: true,
                action: onConfirm,
                mode: btnType || "destructive",
              },
            ]
      }
    >
      <h2>{header}</h2>
      <p>{mainContent || "Вы собираетесь сбросить таймер"}</p>
    </Alert>
  );
};

export default ConfirmResetAlert;
