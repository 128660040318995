/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Timer from "../Timer";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import TimeDisplay from "../TimeDisplay";
import { View, Panel, PanelHeader } from "@vkontakte/vkui";
import useSelector from "../../hooks/useSelector";
import { ReduxState } from "../../redux/types";
import { Theme } from "../../theme/types";
import { Todo as TTodo } from "../../redux/reducers/todo";
import { ReactComponent as AddBtn } from "../../assets/add_main.svg";
import Todo from "../Todo";
import vkBridge from "@vkontakte/vk-bridge";
import useAlertContext from "../AlertContext/useAlertContext";
import AddTodo from "../modals/AddTodo";
import {
  SESSION_STATUS,
  ToogleMessagesFromGroupMutation,
  toogleMessagesFromGroupMutation,
  UpdateAddToHomeScreenDataMutation,
  updateAddToHomeScreenDataMutation,
  AddToHomeScreenDataInput,
} from "bridge";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useMutation } from "@apollo/react-hooks";
import config from "../../config";
import useActions from "../../hooks/useActions";
import { userActions } from "../../redux/reducers/user";
import useAsync from "../../hooks/useAsync";

const styles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  main: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  panel: {
    "& .Panel__centered": {
      paddingBottom: 50,
      height: (props: { height: number }) =>
        props.height < 555 ? "unset" : "100%",
    },
  },
  logo: {
    color: theme.palette.green,
    height: "52px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "20px",
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "27px",
    "& h1": {
      padding: 0,
      margin: 0,
      height: 40,
    },
  },
  header: {
    "& .PanelHeader__container": {},
    "& .PanelHeader-left-in": {
      display: "flex",
      alignItems: "flex-end",
    },
  },
  addBtn: {
    "&:active": {
      opacity: (props: { sessionStatus: SESSION_STATUS }) =>
        props.sessionStatus === SESSION_STATUS.RUNNING ? 1 : 0.8,
    },
  },
  container: {
    height: "100%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
}));

const TimerView = () => {
  const { openAlert } = useAlertContext();
  const sessionType = useSelector(
    (state: ReduxState) => state.timer.sessionType
  );
  const platform = useSelector((state) => state.config.launchParams.platform);
  const sessionStatus = useSelector(
    (state: ReduxState) => state.timer.sessionStatus
  );
  const { width, height } = useWindowDimensions();

  const classes = styles({ sessionType, sessionStatus, height });

  const [toggleMessagesFromGroupRemote] = useMutation<
    ToogleMessagesFromGroupMutation,
    ToogleMessagesFromGroupMutation.Arguments
  >(toogleMessagesFromGroupMutation);

  const [updateAddToHomeScreenData] = useMutation<
    UpdateAddToHomeScreenDataMutation,
    UpdateAddToHomeScreenDataMutation.Arguments
  >(updateAddToHomeScreenDataMutation);

  const { setNotificationsSettings } = useActions({
    setNotificationsSettings: userActions.setNotificationsSettings,
  });

  const applesCount = useSelector((state) => state.user.applesCount);
  const addToHomeScreenData = useSelector(
    (state) => state.user.addToHomeScreenData
  );

  const todos = useSelector((state: ReduxState) => state.todos);

  const notificationSettings = useSelector(
    (state: ReduxState) => state.user.notificationSettings
  );

  const { execute: executeAddToHomeScreen, value, error } = useAsync(
    //@ts-ignore
    () => vkBridge.send("VKWebAppAddToHomeScreen"),
    false
  );

  const updateAddToHomeScreenDataHandler = ({
    isAddedToHomeScreen,
    isAskedAddToHomeScreen,
  }: AddToHomeScreenDataInput) => {
    return updateAddToHomeScreenData({
      variables: {
        input: {
          isAddedToHomeScreen,
          isAskedAddToHomeScreen,
        },
      },
    });
  };

  useEffect(() => {
    value &&
      updateAddToHomeScreenDataHandler({
        isAddedToHomeScreen: true,
        isAskedAddToHomeScreen: true,
      });
    error &&
      updateAddToHomeScreenDataHandler({
        isAddedToHomeScreen: false,
        isAskedAddToHomeScreen: true,
      });
  }, [value, error]);

  useEffect(() => {
    if (vkBridge.supports("VKWebAppAddToHomeScreen")) {
      applesCount > 0 &&
        !addToHomeScreenData.isAskedAddToHomeScreen &&
        executeAddToHomeScreen();
    }

    const messagesAllowed = {
      isAllowedMessagesFromGroup: true,
      isAskedForAllowMessages: true,
      isWorkingWithServerNotification: true,
    };
    const messagesNotAllowed = {
      isAllowedMessagesFromGroup: false,
      isAskedForAllowMessages: true,
      isWorkingWithServerNotification: false,
    };

    if (!notificationSettings.isAskedForAllowMessages) {
      try {
        vkBridge
          .send("VKWebAppAllowMessagesFromGroup", {
            group_id: config.group_id,
          })
          .then((data) => {
            console.log(data);
            toggleMessagesFromGroupRemote({
              variables: {
                input: messagesAllowed,
              },
            });
            setNotificationsSettings(messagesAllowed);
          })
          .catch(() => {
            toggleMessagesFromGroupRemote({
              variables: {
                input: messagesNotAllowed,
              },
            });
            setNotificationsSettings(messagesNotAllowed);
          });
      } catch (e) {
        console.log("VKWebAppAllowMessagesFromGroup error: ", e);
      }
    }
  }, []);

  const activeTodo: TTodo = todos.find((todo: TTodo) => todo.active === true);

  const mainAddTodoHandler = () => {
    openAlert(<AddTodo type="add" fromMain={true} />);
  };

  const alignTopItem = platform === "desktop_web" ? "center" : "flex-end";

  return (
    <View activePanel={"main"}>
      <Panel
        className={classes.panel}
        centered={true}
        id={"main"}
        separator={false}
      >
        <PanelHeader
          left={<div className={classes.logo}>Done Timer</div>}
        ></PanelHeader>
        <div className={classes.container}>
          <div
            style={{
              width: "100vw",
              flex: "0.2 0 0",
              display: "flex",
              alignItems: alignTopItem,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: width > 500 ? "60%" : "90%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              {activeTodo && !activeTodo.completed ? (
                <Todo
                  key={activeTodo._id}
                  _id={activeTodo._id}
                  label={activeTodo.title}
                  timerQty={activeTodo.amountCycles}
                  doneQty={activeTodo.completedCycles}
                  isActive={activeTodo.active}
                  completed={activeTodo.completed}
                  fromMain={true}
                  activeTodo={false}
                  index={activeTodo.index}
                />
              ) : (
                <AddBtn
                  className={classes.addBtn}
                  onClick={() => mainAddTodoHandler()}
                  title="Кнопка добавления задачи"
                />
              )}
            </div>
          </div>
          <div
            style={{
              flex: "0.8 0 0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "30px",
            }}
          >
            <Timer />
            <TimeDisplay />
          </div>
        </div>
      </Panel>
    </View>
  );
};

export default TimerView;
