import React, { PureComponent } from "react";
import ApolloClient from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "@apollo/react-hooks";

import vkBridge, {
  Insets,
  UpdateConfigData,
  VKBridgeSubscribeHandler,
} from "@vkontakte/vk-bridge";

import AppLoadingView from "./views/AppLoadingView";
import AppCrashView from "./views/AppCrashView";

import RootContextProvider, { RootContext } from "./RootContextProvider";
import App from "./App";
import ThemeProvider from "./ThemeProvider";
import ServicePanel from "./ServicePanel";

import { Provider as StoreProvider } from "react-redux";
import { Store } from "redux";
// import {PersistGate} from 'redux-persist/integration/react';
// import {Persistor} from 'redux-persist/lib/types';
import { ReduxState } from "../redux/types";
import createReduxStore from "../redux";
import { configActions, ConfigReducerState } from "../redux/reducers/config";

import config from "../config";

import { getStorage } from "../utils/storage";
import { getLaunchParams } from "../utils/launch-params";

import {
  IsRegisteredQuery,
  isRegisteredQuery,
  RegisterMutation,
  registerMutation,
  UserProfileQuery,
  userProfileQuery,
} from "bridge";
import { TimerReducerState } from "../redux/reducers/timer";

interface IState {
  loading: boolean;
  error: string | null;
  store: Store<ReduxState> | null;
  blocked: boolean;
  platform:
    | "mobile_android"
    | "mobile_iphone"
    | "mobile_web"
    | "desktop_web"
    | "mobile_android_messenger"
    | "mobile_iphone_messenger"
    | null;
  // persistor: Persistor | null;
}

/**
 * Является корневым компонентом приложения. Здесь подгружаются все необходимые
 * для работы приложения данные, а также создаются основные контексты.
 */

class Root extends PureComponent<{}, IState> {
  public state: Readonly<IState> = {
    loading: true,
    error: null,
    store: null,
    blocked: false,
    platform: null,
    // persistor: null,
  };

  private client = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
    uri: config.apiBaseUrl,
    headers: {
      "x-launch-params": window.location.search.slice(1),
    },
  });

  /**
   * Переменная, которая отвечает за статус отправленного события
   * обновления конфига приложения. Необходимо в случае, когда это событие
   * успели отловить, но в тот момент Redux-хранилища еще не существовало
   * @type {null}
   */
  private initialAppConfig: UpdateConfigData | null = null;

  /**
   * Аналогично initialAppConfigSent
   * @type {null}
   */
  private initialAppInsets: Insets | null = null;

  /**
   * Значение для контекста рута
   * @type {{init: () => Promise<void>}}
   */
  private rootContextValue: RootContext = { init: this.init.bind(this) };

  /**
   * Иницилизирует приложение
   */
  private async init() {
    this.setState({ loading: true, error: null });

    let error: string | null = null;
    let store: Store<ReduxState> | null = null;
    // let persistor: Persistor | null = null;

    try {
      // Здесь необходимо выполнить все асинхронные операции и получить
      // данные для запуска приложения, после чего создать хранилище Redux.
      if (vkBridge.supports("VKWebAppSetViewSettings")) {
        vkBridge
          .send("VKWebAppSetViewSettings", {
            status_bar_style: "dark",
            action_bar_color: "#ffffff",
          })
          .catch((err) => console.log(err.error_data.error_reason));
      }
      const [storage, isRegisteredResult, userInfo] = await Promise.all([
        getStorage(),
        this.client.query<IsRegisteredQuery>({ query: isRegisteredQuery }),
        vkBridge.send("VKWebAppGetUserInfo"),
      ]);

      const { first_name, last_name, photo_100 } = userInfo;
      const isRegistered = isRegisteredResult.data.isRegistered;

      if (!isRegistered) {
        await this.client.mutate<RegisterMutation>({
          mutation: registerMutation,
        });
      }

      const userProfileResult = await this.client.query<UserProfileQuery>({
        query: userProfileQuery,
        fetchPolicy: "network-only",
      });
      const userProfile = userProfileResult.data.userProfile;

      let appConfig: ConfigReducerState = {
        app: "vkclient",
        appConfig: config,
        appId: "",
        appearance: "light",
        scheme: "client_light",
        insets: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
        viewportHeight: 0,
        viewportWidth: 0,
        launchParams: getLaunchParams(),
        api_host: '',
      };

      if (this.initialAppConfig) {
        appConfig = { ...appConfig, ...this.initialAppConfig };
      }
      if (this.initialAppInsets) {
        appConfig = { ...appConfig, insets: this.initialAppInsets };
      }

      const createdStore = createReduxStore({
        storage,
        config: appConfig,
        user: {
          fullName: `${first_name} ${last_name}`,
          isRegistered,
          profileImageUrl: photo_100,
          applesCount: userProfile.applesCount!,
          notificationSettings: { ...userProfile.notificationSettings! },
          addToHomeScreenData: { ...userProfile.addToHomeScreenData },
        },
        timer: { ...userProfile.timer! } as TimerReducerState,
        todos: [...userProfile.todos!],
      });

      store = createdStore.store;
    } catch (e) {
      // В случае ошибки, мы её отловим и покажем экран с ошибкой.
      const err = e as Error;
      error = err.message;
    }

    this.setState({
      store,
      // persistor,
      error,
      loading: false,
      platform: getLaunchParams().platform,
    });
  }

  /**
   * Проверяет, является событие VKWebAppUpdateConfig или VKWebAppUpdateInsets
   * чтобы узнать каков конфиг приложения в данный момент, а также - какие
   * внутренние рамки экрана существуют.
   * @param {VKBridgeEvent<ReceiveMethodName>} event
   */
  private onVKBridgeEvent: VKBridgeSubscribeHandler = (event) => {
    const { store } = this.state;

    if (event.detail) {
      if (event.detail.type === "VKWebAppUpdateConfig") {
        if (store) {
          store.dispatch(configActions.updateConfig(event.detail.data));
        } else {
          this.initialAppConfig = event.detail.data;
        }
      } else if (event.detail.type === "VKWebAppUpdateInsets") {
        if (this.state.blocked && this.state.platform === "mobile_iphone") {
          this.setState({ blocked: false });
          setTimeout(() => {
            this.init();
          }, 1000);
        }
        if (store) {
          store.dispatch(configActions.updateInsets(event.detail.data.insets));
        } else {
          this.initialAppInsets = event.detail.data.insets;
        }
      } else if (event.detail.type === "VKWebAppViewRestore") {
        this.init();
      } else if (event.detail.type === "VKWebAppViewHide") {
        this.setState({ blocked: true });
      }
    }
  };

  public componentDidMount() {
    // Когда компонент загрузился, мы ожидаем обновления внутренних рамок
    // и конфига приложения.
    vkBridge.subscribe(this.onVKBridgeEvent);

    // Уведомляем нативное приложение о том, что инициализация окончена.
    // Это заставит нативное приложение спрятать лоадер и показать наше
    // приложение.
    // Причина по которой мы проводим инициализацию здесь - нативное приложение
    // автоматически отправлять информацию о конфиге и внутренних рамках,
    // которая нам нужна.
    vkBridge.send("VKWebAppInit");
    // Инициализируем приложение.
    this.init();
  }

  public componentDidCatch(error: Error) {
    // Отлавливаем ошибку, если выше этого не произошло.
    this.setState({ error: error.message });
  }

  public componentWillUnmount() {
    // При разгрузке удаляем слушателя событий.
    vkBridge.unsubscribe(this.onVKBridgeEvent);
  }

  public render() {
    const {
      loading,
      error,
      store,
      // persistor,
    } = this.state;

    // Отображаем лоадер если приложение еще загружается.
    if (loading) {
      return <AppLoadingView />;
    }

    // Отображаем ошибку если она была.
    if (error) {
      return (
        <ThemeProvider>
          <AppCrashView onRestartClick={this.init.bind(this)} error={error} />
        </ThemeProvider>
      );
    }

    // Исключительные случай, который невозможен
    if (!store) {
      return null;
    }
    // if (!persistor) {
    //   return null;
    // }

    // Отображаем приложение если у нас есть всё, что необходимо.
    return (
      <StoreProvider store={store}>
        {/*<PersistGate loading={loading} persistor={persistor}>*/}
        <RootContextProvider value={this.rootContextValue}>
          <ApolloProvider client={this.client}>
            <ThemeProvider>
              <ServicePanel />
              <App />
            </ThemeProvider>
          </ApolloProvider>
        </RootContextProvider>
        {/*</PersistGate>*/}
      </StoreProvider>
    );
  }
}

export default Root;
