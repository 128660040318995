/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import useActions from "../../hooks/useActions";
import { timerActions } from "../../redux/reducers/timer";
import {
  Select,
  Div,
  FormLayout,
  Cell,
  Switch,
  Spinner,
} from "@vkontakte/vkui";
import { useSelector } from "react-redux";
import { settingsOptions, customSettingsOptions } from "./utils";
import { SETTINGS } from "./types";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "../../theme/types";
import { useMutation } from "@apollo/react-hooks";
import vkBridge from "@vkontakte/vk-bridge";
import config from "../../config";
import moment from "moment";
import {
  UpdateSettingsMutation,
  updateSettingsMutation,
  SESSION_STATUS,
  SESSION_TYPE,
  TimerActionsMutation,
  timerActionsMutation,
  Todo,
  ToogleMessagesFromGroupMutation,
  toogleMessagesFromGroupMutation,
} from "bridge";
import useAlertContext from "../AlertContext/useAlertContext";
import ConfirmResetAlert from "../alerts/ConfirmResetAlert";
import { ReduxState } from "../../redux/types";
import { userActions } from "../../redux/reducers/user";

export interface INewSettings {
  timerTime: number;
  breakTime: number;
  longBreakCycles: number;
  longBreakTime: number;
  timerAutoStart: boolean;
  breaksAutoStart: boolean;
}

const styles = makeStyles((theme: Theme) => ({
  form: {
    marginTop: 4,
    "& label": {
      fontWeight: theme.typography.fontWeightBold,
    },
    "& label .Select__container": {
      color: "#515150",
      fontFamily: theme.typography.fontFamilyTT,
      fontSize: 19,
    },
    "& label .Switch__self:checked + .Switch__pseudo": {
      background: theme.palette.tomato,
      borderColor: theme.palette.tomato,
    },
    "& label .Switch__self:checked+ .Switch__pseudo::before": {
      background: "white",
    },
    "& .Cell .Cell__main": {
      color: "#515150",
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.fontFamilyTT,
      "& .Cell__children": {
        fontSize: 19,
      },
    },
    "& .Cell__in": {
      padding: "0 21px",
    },
    "& .Cell .Cell__main .Cell__children": {
      fontSize: 16,
    },
    "& .FormField__border": {
      background: "none",
      border: "none",
    },

    "& .Select__container": {
      paddingLeft: 0,
      paddingTop: 6,
      lineHeight: "24px",
    },
    "& .FormField.Select .Select__el:focus~.FormField__border": {
      background: "none",
    },
    "& .Select .Icon": {
      transform: "rotate(270deg)",
      color: "#E1E1E1",
      top: "9%",
      right: "-6px",
    },
    "&  .FormLayout__row": {
      paddingBottom: 0,
      "&:not(:nth-last-child(-n+2)):active": {
        background: "#F9F9F9",
        outline: "none",
      },
    },
    "& .FormLayout__row-top": {
      color: "#B4B4B4",
      fontFamily: theme.typography.fontFamilyTT,
      fontSize: 16,
      padding: "2px 21px 0px 21px",
    },
    "& .FormLayout__row .Select select:focus": {},
    "& .FormLayout__row .FormField": {
      paddingTop: 20,
      marginTop: -20,
      marginLeft: 21,
      marginRight: 21,
      borderBottom: "1.2px solid #F3F3F3",
      zIndex: 1,
    },
  },
  container: {
    marginLeft: 12,
  },
  separator: {
    "& .Separator__in": {
      margin: "-10px 0 -3px 0",
      background: "#F3F3F3",
      height: 1,
    },
  },
  lastSeparator: {
    "& .Separator__in": {
      margin: "0 0 0 0",
      background: "#F4F4F4",
    },
  },
  settingsItemActive: {
    "& .FormLayout__row": {
      background: "#F9F9F9",
    },
  },
}));
const TimerSettings = (props: {
  type?: "custom" | "classic";
  styles: string;
  notifications: boolean | "loading";
}) => {
  const [updateRemoteSettings] = useMutation<
    UpdateSettingsMutation,
    UpdateSettingsMutation.Arguments
  >(updateSettingsMutation);

  const [timerActionsRemote] = useMutation<
    TimerActionsMutation,
    TimerActionsMutation.Arguments
  >(timerActionsMutation);

  const [toggleMessagesFromGroupRemote] = useMutation<
    ToogleMessagesFromGroupMutation,
    ToogleMessagesFromGroupMutation.Arguments
  >(toogleMessagesFromGroupMutation);

  const { setNotificationsSettings } = useActions({
    setNotificationsSettings: userActions.setNotificationsSettings,
  });

  const classes = styles();

  const { openAlert } = useAlertContext();

  const { setTimerTicking } = useActions({
    setTimerTicking: timerActions.setTimerTicking,
  });

  // Настройки из хранилища
  const settings = useSelector((state: ReduxState) => state.timer.settings);
  const timer = useSelector((state: ReduxState) => state.timer);
  const todos = useSelector((state: ReduxState) => state.todos);
  const activeTodo = todos.find((todo: Todo) => todo.active === true);

  const current = useSelector(
    (state: ReduxState) => state.timer.currentSettings
  );
  const sessionStatus = useSelector(
    (state: ReduxState) => state.timer.sessionStatus
  );
  const sessionType = useSelector(
    (state: ReduxState) => state.timer.sessionType
  );
  const notificationSettings = useSelector(
    (state: ReduxState) => state.user.notificationSettings
  );

  const timeAim =
    sessionType === SESSION_TYPE.POMODORO
      ? "timerTime"
      : sessionType === SESSION_TYPE.BREAK
      ? "breakTime"
      : "longBreakTime";

  const [newSettings, setNewSettings] = useState<Partial<INewSettings>>({});

  const [settingsEvent, setSettingsEvent] = useState(false);

  const [notifications, setNotifications] = useState<boolean>(
    notificationSettings.isAllowedMessagesFromGroup!
  );

  const { updateSettings } = useActions({
    updateSettings: timerActions.updateSettings,
  });

  const [seenAlert, setSeenAlert] = useState(false);

  const notificationsHandler = () => {
    if (
      !props.notifications &&
      !notificationSettings.isAllowedMessagesFromGroup
    ) {
      vkBridge
        .send("VKWebAppAllowMessagesFromGroup", {
          group_id: config.group_id,
        })
        .then((data) => {
          console.log(data);
          toggleMessagesFromGroupRemote({
            variables: {
              input: {
                isAllowedMessagesFromGroup: true,
                isAskedForAllowMessages: true,
                isWorkingWithServerNotification: true,
              },
            },
          });
          setNotificationsSettings({
            isAllowedMessagesFromGroup: true,
            isAskedForAllowMessages: true,
            isWorkingWithServerNotification: true,
          });
          setNotifications(true);
        })
        .catch((data) => {
          console.log(data);
        });
    } else {
      if (!notificationSettings.isAllowedMessagesFromGroup) {
        toggleMessagesFromGroupRemote({
          variables: {
            input: {
              isAllowedMessagesFromGroup: true,
              isAskedForAllowMessages: true,
              isWorkingWithServerNotification: true,
            },
          },
        });
        setNotificationsSettings({
          isAllowedMessagesFromGroup: true,
          isAskedForAllowMessages: true,
          isWorkingWithServerNotification: true,
        });
      } else if (notificationSettings.isAllowedMessagesFromGroup) {
        toggleMessagesFromGroupRemote({
          variables: {
            input: {
              isAllowedMessagesFromGroup: false,
              isAskedForAllowMessages: true,
              isWorkingWithServerNotification: true,
            },
          },
        });
        setNotificationsSettings({
          isAllowedMessagesFromGroup: false,
          isAskedForAllowMessages: true,
          isWorkingWithServerNotification: true,
        });
      }
    }
  };

  // Если пользователь что-то поменял, то разрешаем ему обновить настройки
  const updateTime = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    !settingsEvent && setSettingsEvent(true);
    const type = e.target.dataset.type as SETTINGS;
    switch (type) {
      case SETTINGS.POMODORO:
        setNewSettings({ ...newSettings, timerTime: parseInt(e.target.value) });
        break;
      case SETTINGS.BREAK:
        setNewSettings({ ...newSettings, breakTime: parseInt(e.target.value) });
        break;
      case SETTINGS.LONG_BREAK:
        setNewSettings({
          ...newSettings,
          longBreakTime: parseInt(e.target.value),
        });
        break;
      case SETTINGS.CYCLES_TO_LONG_BREAK:
        setNewSettings({
          ...newSettings,
          longBreakCycles: parseInt(e.target.value),
        });
        break;
      case SETTINGS.BREAKS_AUTO:
        setNewSettings({
          ...newSettings,
          breaksAutoStart: !settings[current].breaksAutoStart,
        });
        break;
      case SETTINGS.SESSION_AUTO:
        setNewSettings({
          ...newSettings,
          timerAutoStart: !settings[current].timerAutoStart,
        });
        break;
      case SETTINGS.NOTIFICATIONS:
        setNewSettings({
          ...newSettings,
          timerAutoStart: !settings[current].timerAutoStart,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (sessionStatus === SESSION_STATUS.RUNNING && settingsEvent) {
      setTimerTicking(false);
      timerActionsRemote({
        variables: {
          input: {
            savedValues: {
              startedAt: moment().unix(),
              sessionType: timer.sessionType,
              currentCycle: timer.currentCycle,
              currentTime: timer.currentTime,
            },
            amountCycles: activeTodo
              ? activeTodo.amountCycles
              : timer.amountCycles,
            completedCycles: activeTodo
              ? activeTodo.completedCycles
              : timer.completedCycles,
            ticking: false,
          },
        },
      });
    }
  }, [settingsEvent]);

  // Если настройки можно менять, то обновляем их
  useEffect(() => {
    settingsEvent &&
      updateSettings({
        newSettings: { ...newSettings },
        activeTodo,
      });
    settingsEvent &&
      updateRemoteSettings({
        variables: {
          input: {
            ...settings,
            [current]: { ...settings[current], ...newSettings },

            currentSetting: current,
            isCurrentSessionUpdated: !!newSettings[timeAim],
          },
        },
      });
  }, [newSettings]);

  useEffect(() => {
    setNotifications(notificationSettings.isAllowedMessagesFromGroup!);
  }, [notificationSettings.isAllowedMessagesFromGroup]);

  const ref = useRef<HTMLSelectElement>(null);

  const resetTimerWarning = (
    e: React.MouseEvent<HTMLSelectElement | HTMLInputElement, MouseEvent>,
    type?: SESSION_TYPE | "longBreakCycles" | null
  ) => {
    const isTypeRunningNow =
      type === sessionType &&
      !seenAlert &&
      sessionStatus !== SESSION_STATUS.START;

    if (isTypeRunningNow) {
      setSeenAlert(true);
      e.preventDefault();
      openAlert(
        <ConfirmResetAlert
          onConfirm={() => {}}
          mainContent={
            "Осторожно! Если изменить эту настройку, текущий таймер сбросится."
          }
          header={"Таймер сбросится"}
          btnType={"default"}
          btnLabel={"ОК"}
          btnQty={1}
        />
      );
    }
  };

  const options =
    props.type === "custom" ? customSettingsOptions : settingsOptions;

  return (
    <Div className={props.styles}>
      <FormLayout className={classes.form}>
        <Select
          top="Длительность рабочей сессии"
          defaultValue={settings[current].timerTime.toString()}
          onChange={updateTime}
          data-type={SETTINGS.POMODORO}
          onMouseDown={(e) => {
            resetTimerWarning(e, SESSION_TYPE.POMODORO);
          }}
          getRef={ref}
        >
          <option disabled value="">
            Рабочая сессия
          </option>
          {options.pomodoro.map(({ value, label }, key) => (
            <option
              key={key}
              value={value}
              defaultChecked={value === settings[current].timerTime}
            >
              {label}
            </option>
          ))}
        </Select>

        <Select
          top="Длительность маленького перерыва"
          defaultValue={settings[current].breakTime.toString()}
          onChange={updateTime}
          data-type={SETTINGS.BREAK}
          onMouseDown={(e) => {
            resetTimerWarning(e, SESSION_TYPE.BREAK);
          }}
        >
          <option disabled value="">
            Маленький перерыв
          </option>
          {options.break.map(({ value, label }, key) => (
            <option
              key={key}
              value={value}
              defaultChecked={value === settings[current].breakTime}
            >
              {label}
            </option>
          ))}
        </Select>

        <Select
          top="Отрезок до большого перерыва"
          defaultValue={settings[current].longBreakCycles?.toString()}
          onChange={updateTime}
          data-type={SETTINGS.CYCLES_TO_LONG_BREAK}
          onMouseDown={(e) => {
            resetTimerWarning(e, "longBreakCycles");
          }}
        >
          <option disabled value="">
            Отрезок до большого перерыва
          </option>
          {options.cycles.map(({ value, label }, key) => (
            <option
              key={key}
              value={value}
              defaultChecked={value === settings[current].longBreakCycles}
            >
              {label}
            </option>
          ))}
        </Select>

        <Select
          top="Длительность большого перерыва"
          defaultValue={settings[current].longBreakTime.toString()}
          onChange={updateTime}
          data-type={SETTINGS.LONG_BREAK}
          onMouseDown={(e) => {
            resetTimerWarning(e, SESSION_TYPE.LONGBREAK);
          }}
        >
          <option disabled value="">
            Большой перерыв
          </option>
          {options.longBreak.map(({ value, label }, key) => (
            <option
              key={key}
              value={value}
              defaultChecked={value === settings[current].longBreakTime}
            >
              {label}
            </option>
          ))}
        </Select>

        <Cell
          asideContent={
            <Switch
              onChange={updateTime}
              defaultChecked={settings[current].timerAutoStart}
              data-type={SETTINGS.SESSION_AUTO}
              onClick={(e) => {
                resetTimerWarning(e, null);
              }}
            />
          }
        >
          Автостарт для сессии
        </Cell>

        <Cell
          asideContent={
            <Switch
              onChange={updateTime}
              defaultChecked={settings[current].breaksAutoStart}
              data-type={SETTINGS.BREAKS_AUTO}
              onClick={(e) => {
                resetTimerWarning(e, null);
              }}
            />
          }
        >
          Автостарт для перерыва
        </Cell>
        <Cell
          asideContent={
            props.notifications === "loading" ? (
              <Spinner style={{ marginRight: 24 }} size="regular" />
            ) : (
              <Switch
                onChange={() => {
                  setNotifications(
                    notificationSettings.isWorkingWithServerNotification
                      ? !notifications
                      : notifications
                  );
                  notificationsHandler();
                }}
                checked={notifications!}
                data-type={SETTINGS.NOTIFICATIONS}
                onClick={(e) => {
                  resetTimerWarning(e, null);
                }}
              />
            )
          }
        >
          Уведомления
        </Cell>
      </FormLayout>
    </Div>
  );
};

export default React.memo(TimerSettings);
