import React from "react";
import { Cell, Avatar } from "@vkontakte/vkui";
import useSelector from "../../hooks/useSelector";
import { ReduxState } from "../../redux/types";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import { Theme } from "../../theme/types";

const styles = makeStyles((theme: Theme) => ({
  root: {
    "& .Avatar__img": {
      height: "78px !important",
      width: "78px !important",
    },
    "&  .Cell__in": {
      alignItems: "center",
      padding: 0,
    },
    "&  .Cell__before-in> .Avatar": {
      padding: "18px 21px 20px 0 !important",
    },
    "& .Cell__children": {
      color: "#515151",
      fontFamily: theme.typography.fontFamilyTT,
      fontSize: 19,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& .Cell__in .Cell__main": {
      paddingRight: 0,
    },
  },
  container: {
    margin: "0 21px",
    borderBottom: "1.2px solid #F3F3F3",
    paddingTop: 2,
  },
  apples: {
    color: "#B4B4B4",
    fontFamily: theme.typography.fontFamilyTT,
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    "& span": {
      marginLeft: 5,
      marginRight: 6,
      color: "#515151",
      fontSize: 16,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: "19px",
    },
  },
  appleCount: {
    display: "flex",
    height: 14,
    alignItems: "center",
  },

  apple: {
    display: "inline-block",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    background: "linear-gradient(180deg, #FF6F55 0%, #FF785F 100%)",
    opacity: 1,
  },
}));
const ProfileHeader = () => {
  const classes = styles();
  const userInfo = useSelector((state: ReduxState) => state.user);
  return (
    <div className={classes.container}>
      <Cell
        className={classes.root}
        size="l"
        before={<Avatar src={userInfo.profileImageUrl} />}
        bottomContent={
          <div className={classes.apples}>
            Собрано яблок: <span>{userInfo.applesCount}</span>
            <div className={classes.appleCount}>
              <div className={classes.apple}></div>
            </div>
          </div>
        }
      >
        {userInfo.fullName}
      </Cell>
    </div>
  );
};

export default React.memo(ProfileHeader);
