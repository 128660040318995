/**
 * Список полей, которые могут храниться в хранилище bridge.
 */
export enum StorageField {
  ApplicationVisited = "application-visited",
  OnboardingCompleted = "onboarding-completed",
  TodoToolTipSeen = "todo-tooltip-seen",
  MainToolTipSeen = "main-tooltip-seen",
  IsAskedAboutNotify = "asked-notificatiions",
  IsWorkingWithNotifyViaBackend = "is-working-with-notify-via-backend",
}

/**
 * Описывает то, какое поле имеет какой тип данных в хранилище bridge.
 * Пример - [StorageField.Joined]: boolean или [StorageField.Clubs]: IClub[]
 */
export interface StorageValuesMap {
  [StorageField.ApplicationVisited]: boolean;
  [StorageField.OnboardingCompleted]: boolean;
  [StorageField.TodoToolTipSeen]: boolean;
  [StorageField.MainToolTipSeen]: boolean;
  [StorageField.IsAskedAboutNotify]: boolean;
  [StorageField.IsWorkingWithNotifyViaBackend]: boolean;
}

/**
 * Возвращает тип данных для указанного поля хранилища.
 */
export type StorageValueType<T extends StorageField> = StorageValuesMap[T];
