import React from "react";
import { ITimerButtonProps } from "./types";
import useActions from "../../hooks/useActions";
import { timerActions } from "../../redux/reducers/timer";
import moment from "moment";
import useSelector from "../../hooks/useSelector";
import { ReduxState } from "../../redux/types";
import {
  SESSION_STATUS,
  SESSION_TYPE,
  TimerActionsMutation,
  timerActionsMutation,
  ResetTimerMutation,
  resetTimerMutation,
} from "bridge";
import { ReactComponent as StartBtn } from "../../assets/timer-start-btn.svg";
import { ReactComponent as PauseBtn } from "../../assets/timer-pause-btn.svg";
import { ReactComponent as ResetBtn } from "../../assets/timer-reset-btn.svg";
import { userActions } from "../../redux/reducers/user";
import useAlertContext from "../AlertContext/useAlertContext";
import ConfirmResetAlert from "../alerts/ConfirmResetAlert";
import { useMutation } from "@apollo/react-hooks";
import { Todo, todoActions } from "../../redux/reducers/todo";

const TimerButton = (props: ITimerButtonProps) => {
  const { openAlert } = useAlertContext();

  const [timerActionsRemote] = useMutation<
    TimerActionsMutation,
    TimerActionsMutation.Arguments
  >(timerActionsMutation);

  const [resetTimerRemote] = useMutation<
    ResetTimerMutation,
    ResetTimerMutation.Arguments
  >(resetTimerMutation);

  const type = props.type;

  const { setTimerTicking } = useActions({
    setTimerTicking: timerActions.setTimerTicking,
  });

  const sessionStatus = useSelector(
    (state: ReduxState) => state.timer.sessionStatus
  );

  const timerData = useSelector((state) => state.timer);

  const todos = useSelector((state) => state.todos);

  const activeTodo = todos.find((todo: Todo) => todo.active === true) as Todo;

  const completedCyles = useSelector(
    (state: ReduxState) => state.timer.completedCycles
  );

  const sessionType = useSelector(
    (state: ReduxState) => state.timer.sessionType
  );

  const { setSavedValues } = useActions({
    setSavedValues: timerActions.setSavedValues,
  });

  const { editTodo } = useActions({
    editTodo: todoActions.editTodo,
  });

  const { setApplesCount } = useActions({
    setApplesCount: userActions.setApplesCount,
  });

  const { reset } = useActions({
    reset: timerActions.reset,
  });

  const { setCompletedPoints } = useActions({
    setCompletedPoints: timerActions.setCompletedPoints,
  });

  const startHandler = () => {
    setSavedValues({
      startedAt: moment().unix(),
      sessionType: timerData.sessionType,
      currentCycle: timerData.currentCycle,
      currentTime: timerData.currentTime,
    });
    setTimerTicking(true);
    if (
      sessionType === SESSION_TYPE.POMODORO &&
      activeTodo &&
      activeTodo.completedCycles >= activeTodo.amountCycles &&
      activeTodo.amountCycles !== 10
    ) {
      editTodo({
        _id: activeTodo._id,
        amountCycles: activeTodo.amountCycles + 1,
      });
    }
    timerActionsRemote({
      variables: {
        input: {
          savedValues: {
            startedAt: moment().unix(),
            sessionType: timerData.sessionType,
            currentCycle: timerData.currentCycle,
            currentTime: timerData.currentTime,
          },
          amountCycles: activeTodo
            ? activeTodo.amountCycles
            : timerData.amountCycles,
          completedCycles: activeTodo
            ? activeTodo.completedCycles
            : timerData.completedCycles,
          ticking: true,
        },
      },
    });
  };

  const pauseHandler = () => {
    if (timerData.currentTime <= 1) {
      return;
    }
    setTimerTicking(false);

    timerActionsRemote({
      variables: {
        input: {
          savedValues: {
            startedAt: moment().unix(),
            sessionType: timerData.sessionType,
            currentCycle: timerData.currentCycle,
            currentTime: timerData.currentTime,
          },
          amountCycles: activeTodo
            ? activeTodo.amountCycles
            : timerData.amountCycles,
          completedCycles: activeTodo
            ? activeTodo.completedCycles
            : timerData.completedCycles,
          ticking: false,
        },
      },
    });
  };

  const resetHandler = () => {
    const handler = () => {
      if (!activeTodo && completedCyles > 0) {
        setCompletedPoints({
          points: completedCyles,
        });
        setApplesCount(completedCyles);
      }
      resetTimerRemote();
      reset();
    };
    openAlert(
      <ConfirmResetAlert
        onConfirm={handler}
        mainContent={"Вы действительно хотите сбросить таймер?"}
        header={"Сброс таймера"}
      />
    );
  };

  const startBtn = (
    <div
      className={props.className}
      onClick={() =>
        sessionStatus === SESSION_STATUS.RUNNING
          ? pauseHandler()
          : startHandler()
      }
    >
      {sessionStatus === SESSION_STATUS.RUNNING ? (
        <PauseBtn
          color={
            props.session === SESSION_TYPE.POMODORO ? "#FF745B" : "#6CC584"
          }
        />
      ) : (
        <StartBtn
          color={
            props.session === SESSION_TYPE.POMODORO ? "#FF745B" : "#6CC584"
          }
        />
      )}
    </div>
  );

  const resetBtn = (
    <div className={props.className} onClick={() => resetHandler()}>
      <ResetBtn
        color={props.session === SESSION_TYPE.POMODORO ? "#FF745B" : "#6CC584"}
      />
    </div>
  );

  if (type === "start") return startBtn;

  return resetBtn;
};

export default React.memo(TimerButton);
